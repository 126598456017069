export default {
  fromContainer(container){
    return {
      ...container,
      organization_id:       container.organization_id,
      container_id:          container.id,
      vessel_plan_id:        container.vessel_plan_id,
      container_number:      container.number,
      container_status:      container.status,
      size:                  container.container_type,
      weight:                container.weight_kg,
      weight_quantifier:     'kg',
      temperature:           container.set_point_temperature,
      carrier_code:          container.shipping_line,
      dangerous_goods_code:  (container.hazmat_codes || []).join(','),
      dangerous_goods_class: (container.hazmat_classes || []).join(','),
      oog_general:           container.over_height || container.over_width || container.over_length
    }
  }
}
