import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pickFromAllowedValues", "allowedValuesWrapper", "allowedValues"]

  connect() {
    this.toggleAllowedValues()
  }

  toggleAllowedValues() {
    const isChecked = this.pickFromAllowedValuesTarget.checked

    isChecked ? this.showAllowedValues() : this.hideAllowedValues()
  }

  showAllowedValues() {
    $(this.allowedValuesWrapperTarget).collapse('show')
    this.allowedValuesTarget.disabled = false
  }

  hideAllowedValues() {
    $(this.allowedValuesWrapperTarget).collapse('hide')
    this.allowedValuesTarget.disabled = true
  }
}
