import '@hotwired/turbo-rails'

// This monkey patch enables `data-turbo-progress-bar` option to control the progress bar.
// Can be removed when https://github.com/hotwired/turbo/issues/540 is resolved.
export const useTurboProgressBar = function() {
  const adapter = Turbo.navigator.delegate.adapter;
  const progressBar = adapter.progressBar;
  const session = Turbo.session;

  let progressBarTimeout = null;

  document.addEventListener('turbo:before-fetch-request', (event) => {
    const target = event.target;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    if ('true' === target.getAttribute('data-turbo-progress-bar')) {
      if (!progressBarTimeout) {
        progressBar.setValue(0);
      }

      progressBarTimeout = window.setTimeout(() => progressBar.show(), session.progressBarDelay);
    }
  });

  document.addEventListener('turbo:before-fetch-response', () => {
    if (progressBarTimeout) {
      window.clearTimeout(progressBarTimeout);
      progressBar.hide();
      progressBarTimeout = null;
    }
  });
};
