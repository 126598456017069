/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

////////////
// Fonts //
////////////

import '@fontsource/roboto';

////////////
// Styles //
////////////

import '../stylesheets/application.scss'

//////////////
// Stimulus //
//////////////

import 'core-js/stable'                                                        // Rails webpacker default, used for polyfills
import 'regenerator-runtime/runtime'                                           // Rails webpacker default, used for generator (async/promise) polyfill
import "@hotwired/turbo-rails"                                                 // Turbo
import { Application } from "@hotwired/stimulus"                               // Stimulus
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"    // Allows simple loading of stimulus controllers
import { useTurboProgressBar } from '../mixins/use_turbo_progress_bar'         // Enables `data-turbo-progress-bar` option

window.Stimulus = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context))

useTurboProgressBar();

// Opt into using turbo gradually
Turbo.session.drive = false

/////////////////////////////
// jQuery/Vanilla JS Setup //
/////////////////////////////

// Vendor
import SearchApi from 'js-worker-search'                                      // used in 'visible_list_tally'
import * as d3 from 'd3'                                                      // used only on 'gate_movements_punchcard' and 'vessel_movements_punchcard'

// The lines below give access to ES6 modules to Sprockets
import Units from '../modules/units'
import ArrayHistory from '../modules/array_history'
import Filter from '../modules/filter'

window.Units = Units
window.ArrayHistory = ArrayHistory
window.SearchApi = SearchApi
window.Filter = Filter

//////////////
// Airbrake //
//////////////

import { Notifier } from '@airbrake/browser';

const environment = process.env.RAILS_ENV || 'staging'

if (['production', 'qa', 'staging'].includes(environment)) {
  const airbrake = new Notifier({
    projectId: process.env.AIRBRAKE_PROJECT_ID,
    projectKey: process.env.AIRBRAKE_API_KEY,
    environment: environment,
    keysBlocklist: [
      /password|otp_attempt/, // regexp match
    ]
  });

  window.addEventListener('error', event => {
    airbrake.notify({ error: event.error })
  })
}

///////////////
// Vue Setup //
///////////////

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueClipboard from 'vue-clipboard2'
import PortalVue from 'portal-vue'
import Popover from 'vue-js-popover'
import { RecurrenceEditorPlugin } from '@syncfusion/ej2-vue-schedule'
import VueI18n from 'vue-i18n'

window.Vue    = Vue
window.store  = store
window.router = new VueRouter({ mode: 'history' })
window.i18n   = new VueI18n({ locale: window.locale, fallbackLocale: 'en' })

Vue.use({
  install(){
    Vue.i18n = window.i18n
  }
})
Vue.use({
  store,
  install: (Vue, options) => {
    Vue.prototype.$store = store
  }
})
Vue.use(VueRouter)
Vue.use(VueClipboard)
Vue.use(Popover)
Vue.use(PortalVue)
Vue.use(RecurrenceEditorPlugin)

Vue.component('beta-octo-vessel-plan',              () => import('../components/beta_octo_vessel_plan'))
Vue.component('octo-accounts-manager',              () => import('../components/octo_accounts_manager'))
Vue.component('octo-autocomplete',                  () => import('../components/octo_autocomplete'))
Vue.component('octo-bol-search-result',             () => import('../components/octo_bol_search_result'))
Vue.component('octo-booking-csv-importer',          () => import('../components/octo_booking_csv_importer'))
Vue.component('octo-booking-search-result',         () => import('../components/octo_booking_search_result'))
Vue.component('octo-button',                        () => import('../components/octo_button'))
Vue.component('octo-button-group',                  () => import('../components/octo_button_group'))
Vue.component('octo-color-picker',                  () => import('../components/octo_color_picker'))
Vue.component('octo-complete-voyage',               () => import('../components/octo_complete_voyage'))
Vue.component('octo-container-number',              () => import('../components/octo_container_number'))
Vue.component('octo-container-search-result',       () => import('../components/octo_container_search_result'))
Vue.component('octo-container-stack-animation',     () => import('../components/octo_container_stack_animation'))
Vue.component('octo-container-status',              () => import('../components/octo_container_status'))
Vue.component('octo-containers-queue',              () => import('../components/octo_containers_queue'))
Vue.component('octo-containers-queue-item',         () => import('../components/octo_containers_queue_item'))
Vue.component('octo-date-time-selector',            () => import('../components/octo_date_time_selector'))
Vue.component('octo-dock-receipt-search-result',    () => import('../components/octo_dock_receipt_search_result'))
Vue.component('octo-gate-weights',                  () => import('../components/octo_gate_weights'))
Vue.component('octo-general-cargo-search-result',   () => import('../components/octo_general_cargo_search_result'))
Vue.component('octo-icon',                          () => import('../components/octo_icon'))
Vue.component('octo-manifest-importers-admin-list', () => import('../components/octo_manifest_importers_admin_list'))
Vue.component('octo-manifest-importers-list',       () => import('../components/octo_manifest_importers_list'))
Vue.component('octo-modal',                         () => import('../components/octo_modal'))
Vue.component('octo-multiple-lift',                 () => import('../components/octo_multiple_lift'))
Vue.component('octo-navbar-notifications',          () => import('../components/octo_navbar_notifications'))
Vue.component('octo-pagination',                    () => import('../components/octo_pagination'))
Vue.component('octo-register-multiple-lift',        () => import('../components/octo_register_multiple_lift'))
Vue.component('octo-scheduler',                     () => import('../components/octo_scheduler'))
Vue.component('octo-seals',                         () => import('../components/octo_seals'))
Vue.component('octo-search-all-things',             () => import('../components/octo_search_all_things'))
Vue.component('octo-search-results',                () => import('../components/octo_search_results'))
Vue.component('octo-sticky',                        () => import('../components/octo_sticky'))
Vue.component('octo-stowage-location-tallies',      () => import('../components/octo_stowage_location_tallies'))
Vue.component('octo-switch',                        () => import('../components/octo_switch'))
Vue.component('octo-tab',                           () => import('../components/octo_tab'))
Vue.component('octo-table',                         () => import('../components/octo_table'))
Vue.component('octo-table-booking',                 () => import('../components/octo_table_booking'))
Vue.component('octo-table-dynamic',                 () => import('../components/octo_table_dynamic'))
Vue.component('octo-tally-lift',                    () => import('../components/octo_tally_lift'))
Vue.component('octo-tasks-notification',            () => import('../components/octo_tasks_notification'))
Vue.component('octo-temperature-input',             () => import('../components/octo_temperature_input'))
Vue.component('octo-text-copy',                     () => import('../components/octo_text_copy'))
Vue.component('octo-timeline',                      () => import('../components/octo_timeline'))
Vue.component('octo-token-select',                  () => import('../components/octo_token_select'))
Vue.component('octo-tutorials',                     () => import('../components/octo_tutorials'))
Vue.component('octo-vessel-bay-group',              () => import('../components/octo_vessel_bay_group'))
Vue.component('octo-vessel-configure-slots',        () => import('../components/octo_vessel_configure_slots'))
Vue.component('octo-vessel-plan',                   () => import('../components/octo_vessel_plan'))
Vue.component('octo-vessel-plan-inspector',         () => import('../components/octo_vessel_plan_inspector'))
Vue.component('octo-voyage-timeline',               () => import('../components/octo_voyage_timeline'))
Vue.component('octo-weight-input',                  () => import('../components/octo_weight_input'))
Vue.component('octo-yard-area-grid',                () => import('../components/octo_yard_area_grid'))
Vue.component('octo-yard-area-grid-grid',           () => import('../components/octo_yard_area_grid_grid'))
Vue.component('octo-yard-grid-container-table',     () => import('../components/octo_yard_grid_container_table'))
Vue.component('octo-yard-grid-chassis-table',       () => import('../components/octo_yard_grid_chassis_table'))
Vue.component('octo-yard-heap',                     () => import('../components/octo_yard_heap'))
Vue.component('octo-yard-slot-inspector',           () => import('../components/octo_yard_slot_inspector'))
Vue.component('octo-yard-slots-new',                () => import('../components/octo_yard_slots_new'))
Vue.component('octo-yard-warehouse',                () => import('../components/octo_yard_warehouse'))
