//= require batch_actions_modal

var Octopi = window.Octopi || {};
Octopi.Containers = Octopi.Containers || {};

Octopi.Containers.LoadListModal = (function(){
  var formSelector = 'form#bulk-update-containers-form';
  var submitSelector = '#add-to-load-list-modal-submit';
  var modalSelector = '#add-to-load-list-modal';
  var voyageSelector = '#bulk_update_voyage_id';
  var state;
  var stateItemsToInputAttr;

  function toggleSubmitButton() {
    if($(voyageSelector).val()) {
      $(submitSelector).prop("disabled", false);
    } else {
      $(submitSelector).prop("disabled", true);
    }
  }

  function hideTable() {
    // Hide tables that are used to select quantities
    // because this is not supported on matching all.
    $('#add-to-load-list-table-wrapper').hide();
  }

  function showTable() {
    // Show tables that are used to select quantities
    $('#add-to-load-list-table-wrapper').show();
  }

  function buildTable() {
    var tbody = [];
    state['bulk_update']['items'].each(function(){
      tbody.push('<tr>');
      tbody.push('<td>' + this.cargo + '</td>');
      tbody.push('<td class="modal-statuses-col"><select id="container-status-' + this.containerId +
        '" class="form-control js-loading-status-button currently-' + this.status + '"><option value="empty" ' +
          (this.status === 'empty' ? 'selected' : '') + '>Empty</option><option value="full" ' +
            (this.status === 'full' ? 'selected' : '') + '>Full</option></select></td>');

      if (this.bol.length > 0) {
        tbody.push('<td>' + this.bol + '</td>');
      } else if (this.booking.length > 0) {
        tbody.push('<td>' + this.booking + '</td>');
      }
      tbody.push('</tr>');
    })

    $('#add-to-load-list-modal-tbody').html(tbody.join(''));
  }

  function submitLoadListForm(event) {
    $(submitSelector).prop('disabled', true);
    $('.js-loading-status-button').prop('disabled', true); //

    if(!state['bulk_update']['apply_to_all_filter_matches']) {
      state['bulk_update']['items'].each(function(){
        var id = this.containerId;
        var status = $('#container-status-' + id).val();

        $("input[type='hidden'][name=\"bulk_update[containers][" + id + ']"').val(status)
      });
    }

    $(formSelector).submit();
  }

  function setAllContainersStateFull(event) {
    event.stopPropagation();

    $('.modal-statuses-col select').each(function() {
      $(this).children().removeAttr('selected');
      $(this).children('option[value="full"]').attr('selected', 'selected')
      $(this).val('full').change();
    })
  }

  function setAllContainersStateEmpty(event) {
    event.stopPropagation();

    $('.modal-statuses-col select').each(function() {
      $(this).children().removeAttr('selected');
      $(this).children('option[value="empty"]').attr('selected', 'selected')
      $(this).val('empty').change();
    })
  }

  function setAllContainersStateCurrent(event) {
    event.stopPropagation();

    $('.modal-statuses-col select').each(function() {
      $(this).children().removeAttr('selected');
      if ($(this).hasClass('currently-empty')) {
        $(this).children('option[value="empty"]').attr('selected', 'selected')
        $(this).val('empty').change();
      } else {
        $(this).children('option[value="full"]').attr('selected', 'selected')
        $(this).val('full').change();
      }
    })
  }

  function initializeLoadListModal() {
    toggleSubmitButton();

    Octopi.BatchActionsModal.initializeModal(modalSelector, formSelector, state, stateItemsToInputAttr);

    if(state['bulk_update']['apply_to_all_filter_matches']) {
      hideTable();
    } else {
      showTable();
      buildTable();
    }

    $(voyageSelector).on('select2:change', toggleSubmitButton);
    $(submitSelector).on('click', submitLoadListForm);
    $('#set-all-containers-full').on('click', setAllContainersStateFull);
    $('#set-all-containers-empty').on('click', setAllContainersStateEmpty);
    $('#set-all-containers-current').on('click', setAllContainersStateCurrent);
  }

  function init(batchActionsState, itemsToInputAttrs) {
    state = batchActionsState;
    stateItemsToInputAttr = itemsToInputAttrs;

    $(modalSelector).on('show.bs.modal', initializeLoadListModal);
  }

  return {
    init: init,
  }
}());
