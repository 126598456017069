import { Controller } from "@hotwired/stimulus"
import jsonview from '@pgrabovets/json-view';

export default class extends Controller {
  static targets = ['source', 'preview']

  static values = {
    expandRoot: { type: Boolean, default: true },
    expandAll:  { type: Boolean, default: false }
  }

  connect() {
    const data = this.sourceTarget.textContent;
    const tree = jsonview.create(data);

    this.previewTarget.innerHTML = '';
    jsonview.render(tree, this.previewTarget);

    if(this.expandAllValue) {
      jsonview.expand(tree);
    } else if(this.expandRootValue) {
      jsonview.toggleNode(tree);
    }
  }
}
