import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "printButton",
    "selectAll",
  ]

  // Handle print button click and generation of receipt.
  printReceipts() {
    if (this.printButtonTarget.hasAttribute("disabled")) {
      this.showTooltip();
      setTimeout(() => {
        this.hideTooltip();
      }, 1000);

      return
    }

    const selectedIds = this.getSelectedIds();

    if (selectedIds.length > 0) {
      window.open(`/stock_transfers/print?stock_transfer_ids=${selectedIds.join(",")}`, '_blank');
    } else {
      // If selectedIds is empty, open the link in the same tab
      window.location.href = "/stock_transfers/print";
    }
  }

  // Show Tooltip logic
  showTooltip() {
    if (this.printButtonTarget.hasAttribute("disabled")) {
      // Set the 'data-toggle' and 'title' attributes
      this.element.setAttribute('data-toggle', 'tooltip');
      this.element.setAttribute('title', 'Filter by both consignees');
      $(this.element).tooltip();
    }
  }

  // Hide tooltip logic
  hideTooltip() {
    $(this.element).tooltip('hide');
  }

  // Handle select all checkboxes
  selectAll(event) {
    const checkAll = event.target.checked;

    document.querySelectorAll('.selectable-stock-transfer').forEach((checkbox) => {
      checkbox.checked = checkAll;
    });
  }

  // Helper method to get selected checkbox ids
  getSelectedIds() {
    const checkboxes = document.querySelectorAll(".selectable-stock-transfer:checked");
    return Array.from(checkboxes).map((checkbox) => checkbox.value);
  }
}
