import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["row"]

  search(event) {
    const query = event.target.value.toLowerCase()

    if (query === "") {
      this.rowTargets.forEach(row => row.style.display = "")
    } else {
      this.rowTargets.forEach(row => {
        const text = row.textContent.toLowerCase()
        row.style.display = text.includes(query) ? "" : "none"
      })
    }
  }
}
