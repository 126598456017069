import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static values = {
    voyageId: Number
  };

  ///////////////////
  //// Callbacks ////
  ///////////////////

  connect() {
    const self = this;

    $(document).ready(function() {
      google.charts.load(
        'current',
        {
          packages: ['corechart'],
          callback: function() { return self.drawProductivityChart(self.voyageIdValue, null) }
        }
      );
    })
  }

  /////////////////
  //// Actions ////
  /////////////////

  refreshChart(event) {
    if(typeof google === 'undefined') {
      console.warn('google chart is not defined');
      return;
    }

    const craneId = event.currentTarget.value;
    this.drawProductivityChart(this.voyageIdValue, craneId);
  }

  /////////////////
  //// Helpers ////
  /////////////////

  drawProductivityChart(voyageId, craneId) {
    var constructChart = (function(voyageData) {
      let data = this.constructChartData(voyageData);
      let options = this.constructChartOptions();

      let view = new google.visualization.DataView(data);
      view.setColumns([
        0,
        1,
        {
          calc: "stringify",
          sourceColumn: 1,
          type: "string",
          role: "annotation"
        },
        2,
        {
          calc: "stringify",
          sourceColumn: 2,
          type: "string",
          role: "annotation"
        }
      ]);

      const chartDiv = document.getElementById('voyage-productivity-chart');
      new google.visualization.ComboChart(chartDiv).draw(view, options);
    }).bind(this);

    $.ajax({
      url: `/data/voyage/${voyageId}.json`,
      method: 'GET',
      data: { equipment_id: craneId },
      dataType: 'json',
      success: constructChart
    });
  }

  constructChartData(voyageData) {
    let data = new google.visualization.DataTable();

    data.addColumn('datetime', 'Hour');
    data.addColumn('number', 'Loading');
    data.addColumn('number', 'Discharge');

    voyageData.data.forEach(function(row) {
      data.addRow([new Date(row[0]), row[1], row[2]]);
    });

    return data;
  } 

  constructChartOptions() {
    return {
      legend: { position: "bottom" },
      hAxis: { textStyle: { fontSize: 10 } },
      vAxis: {
        title: 'Moves per hour',
        minValue: 0
      },
      isStacked: true,
      bar: { groupWidth: '85%' },
      chartArea: {
        top: 10,
        height: '75%',
        left: '6%',
        width: '90%'
      },
      seriesType: 'bars',
      series: {
        0: { color: '#9471df' }, // Loading
        1: { color: '#046B36' }  // Discharge
      }
    };
  }
}
