import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'item',    // Toggleable Item
    'sidebar', // Usually the "Filter" panel
    'mainbar'  // Usually the "Filter Results" table
  ]

  static values = {
    itemToggled:    { type: Boolean, default: false },
    sidebarClasses: { type: Array, default: ['hidden'] },
    mainbarClasses: { type: Array, default: ['col-md-9', 'col-md-pull-3'] },
    toggleTitle:    { type: String, default: "" }
  }

  connect() {
    if(this.itemToggledValue) this.toggleItems();
  }

  /* ===== TEMPLATE =====
    <div data-controller="visibility">
      <button
        data-action="visibility#toggleSidebar"
        data-toggle-title-with="Show Filter"
      >
        Hide Filter
      </button>

      <div
        class="sidebar"
        data-visibility-target="sidebar"
        data-visibility-sidebar-classes-value='["hide"]'
      >
        ...
      </div>

      <div
        class="mainbar"
        data-visibility-target="sidebar"
        data-visibility-mainbar-classes-value='["col-md-9", "col-md-pull-3"]'
      >
        ...
      </div>
    </div>
  */
  toggleSidebar(event) {
    if(!this.hasSidebarTarget) return;
    if(!this.hasMainbarTarget) return;

    // Toggle Sidebar
    this.sidebarClassesValue.forEach(klass => {
      this.sidebarTarget.classList.toggle(klass);
    });

    // Resize Mainbar
    this.mainbarClassesValue.forEach(klass => {
      this.mainbarTarget.classList.toggle(klass);
    });

    // Toggle Button Text
    if(!this.hasToggleTitleValue) return;

    const toggleButton = $(event.currentTarget).find('.title');
    if(toggleButton.length === 0) toggleButton = $(event.currentTarget);

    const newTitle = this.toggleTitleValue;
    const oldTitle = toggleButton.text();

    toggleButton.text(newTitle);
    this.toggleTitleValue = oldTitle;
  }

  toggleItems() {
    if(!this.hasItemTarget) return;

    this.itemTargets.forEach(item => {
      item.classList.toggle("hidden")
    })
  }

  showItems() {
    if(!this.hasItemTarget) return;

    this.itemTargets.forEach(item => {
      item.classList.remove("hidden")
    })
  }

  hideItems() {
    if(!this.hasItemTarget) return;

    this.itemTargets.forEach(item => {
      item.classList.add("hidden")
    })
  }
}
