import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["unhide"]

  togglePassword(e) {
    if (this.unhideTarget.type === "password") {
      this.unhideTarget.type = "text";
      e.target.classList.remove('glyphicon-eye-close');
      e.target.classList.add('glyphicon-eye-open');
    } else {
      this.unhideTarget.type = "password";
      e.target.classList.remove('glyphicon-eye-open');
      e.target.classList.add('glyphicon-eye-close');
    }
  }
}
