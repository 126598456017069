import { propertyOf } from 'underscore'

export default {
  create({ operation, property, propertyPath, matcher }){
    return {
      name: this.generateName({ operation, property, matcher }),
      property_path: propertyPath,
      operation,
      matcher
    }
  },

  generateName({ operation, property, matcher }){
    if(operation == 'equality')   return `${property} is ${matcher}`
    if(operation == 'inequality') return property ? `${property} is not ${matcher}` : `Not ${matcher}`
    if(operation == 'not_between') {
      if(matcher.to == Infinity) return `${property} is not bigger than ${matcher.from}`
      return `${property} is not between ${matcher.from} and ${matcher.to - 1}`
    }
  },

  isEnabled(filters, filter){
    return !filters.find(f => f.name == filter.name)
  },

  toggle(filters, filter){
    const index = filters.findIndex(f => f.name == filter.name)
    if(index == -1) return [...filters, { ...filter, enabled: true }]
    filters.splice(index, 1)
    return filters
  },

  isFilteredOut: function(userFilters, cell, options){
    options = options || {}
    return !userFilters.filter(({ enabled }) => enabled)
                       .every(({ property_path, operation, matcher }) => {
                         const value = propertyOf(cell.data)(property_path)
                         if(value == undefined && options.allowNotFound) return
                         return this[operation](value, matcher)
                       })
  },

  equality(value, matcher){
    return value == matcher
  },

  inequality(value, matcher){
    return !this.equality(value, matcher)
  },

  between(value, { from, to }){
    value = parseFloat(value)
    return value >= from && value < to
  },

  not_between(value, { from, to }){
    return !this.between(value, { from, to })
  },

  valueFilteredOut: function(filters, { propertyPath, value, ...options } = { options: {} }){
    var null_values = ['N/A', 'unknown', undefined, null]
    return !!filters.find(filter => {
      const initialCheck = filter.enabled &&
                           filter.property_path.join(' ') == propertyPath.join(' ') &&
                           (options.allowNotFound || value !== undefined)
      if(!initialCheck) return false

      const statement = null_values.includes(filter.matcher) ||
                        null_values.includes(value) ||
                        (value == filter.matcher),

            expected  = filter.operation == 'equality' ? true : false

      return statement !== expected
    })
  }
}
