import { Controller } from "@hotwired/stimulus"

import Dropzone from "dropzone";
import $ from "jquery"

Dropzone.autoDiscover = false;

export default class extends Controller {

  connect() {

    let submitButton = $(this.element).closest("form").find("input[type='submit']")[0]
    let dropzone = $(this.element).closest("form").find(".dropzone")[0]

    new Dropzone(this.element, {
      addRemoveLinks: true,
      acceptedFiles: 'image/*',
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 15,
      maxFiles: 15,
      addRemoveLinks: true,
      clickable: dropzone,
      previewsContainer: dropzone,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      init: function() {

        var myDropzone = this;

        submitButton.addEventListener('click', function(e) {
          if (myDropzone.getQueuedFiles().length > 0) {
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          }
        })
      },
      success: function(_file, jqXHR) {
        window.location.href = jqXHR.redirect;
      },
      error: function(file, jqXHR) {
        file.status = Dropzone.QUEUED
      }
    })
  }
}
