import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

export default class extends Controller {
  static targets = [
    "canvas",
    "input"
  ]

  static values = {
    backgroundImage: String,
    damageImage: String
  }

  connect() {
    let context = this.canvasTarget.getContext('2d')
    let isDrawing = false;

    this.drawCanvas(this.damageImageValue || this.backgroundImageValue)

    context.fillCircle = function(x, y, radius, fillColor) {
      this.fillStyle = fillColor;
      this.beginPath();
      this.moveTo(x, y);
      this.arc(x, y, radius, 0, Math.PI * 2, false);
      this.fill();
    };

    // bind mouse events
    this.canvasTarget.onmousemove = function(e) {
      if (!isDrawing) {
        return;
      }
      var x = e.offsetX;
      var y = e.offsetY;
      var radius = 3; // or whatever
      var fillColor = '#ff0000';
      context.fillCircle(x, y, radius, fillColor);
    };

    var drawingCallback = function(e) {
      isDrawing = true;
    };

    this.canvasTarget.addEventListener("mousedown", drawingCallback)
    this.canvasTarget.addEventListener("touchstart", drawingCallback)

    let element = this.canvasTarget;
    this.canvasTarget.onmouseup = (e) => {
      isDrawing = false;
      let formData = $(element).get(0).toDataURL("image/png")
      this.inputTarget.value = formData
      $(this.element).closest("form").trigger("change")
    };

    // Bind touch events (for tablets)
    var start = function(e) {
      var position = $(elementId).position();
      ctx.beginPath();
      ctx.strokeStyle = '#ff0000';
      x = e.changedTouches[0].pageX - position.left;
      y = e.changedTouches[0].pageY - position.top - 20;
      ctx.moveTo(x,y);
    };
    var move = function(e) {
      ctx.strokeStyle = '#ff0000';
      var position = $(elementId).position();
      e.preventDefault();
      x = e.changedTouches[0].pageX - position.left;
      y = e.changedTouches[0].pageY - position.top - 20;
      ctx.lineTo(x,y);
      ctx.stroke();
    };

    this.canvasTarget.addEventListener("touchstart", start, false);
    this.canvasTarget.addEventListener("touchmove", move, false);
  }

  drawCanvas(backgroundData) {
    let context = this.canvasTarget.getContext('2d')

    var bgImage = new Image();
    bgImage.setAttribute('crossorigin', 'anonymous');
    bgImage.src = backgroundData
    bgImage.onload = function() {
      context.drawImage(bgImage, 0, 0, 500, 325);
    }
  }

  clear() {
    this.drawCanvas(this.backgroundImageValue);
  }
}
