import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

export default class extends Controller {
  static targets = [
    'scaleWeightIn',
    'scaleWeightOut',
    'totalCalculatedWeight',
    'errorMsg'
  ]

  static values = {
    purpose: String,
    url: String,
    totalCalculatedWeight: Number
  }

  recalculateTotalWeight() {
    let scaleWeightInNumber = Number(this.scaleWeightInTarget.value);
    let scaleWeightOutNumber = Number(this.scaleWeightOutTarget.value);
    let purpose = this.purposeValue;
    let calculatedWeight = 0;
    let onlyScaleWeightIn = scaleWeightInNumber > 0 && scaleWeightOutNumber == 0

    if (purpose == 'Drop Off') {
      calculatedWeight = scaleWeightInNumber - scaleWeightOutNumber;
    } else if (purpose == 'Pick Up') {
      calculatedWeight = scaleWeightOutNumber - scaleWeightInNumber;
    }
    if (onlyScaleWeightIn){
      calculatedWeight = 0;
    }
    $(this.totalCalculatedWeightTarget).val(calculatedWeight);

    if (calculatedWeight >= 0) {
      $(this.totalCalculatedWeightTarget).removeClass('has-error');
      $(this.errorMsgTarget).addClass('hidden');
      this.updateWeightsToGCInterchange();
    } else {
      $(this.totalCalculatedWeightTarget).addClass('has-error');
      $(this.errorMsgTarget).removeClass('hidden');
      $(this.errorMsgTarget).html('<p style="color:#e73c3c">Weight cannot be less than zero.</p>');
    }
  }

  updateWeightsToGCInterchange() {
    $.ajax({
      url: this.urlValue,
      method: 'PUT',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      data: JSON.stringify({
        scale_weight_in: this.scaleWeightIn,
        scale_weight_out: this.scaleWeightOut,
        calculated_cargo_weight: this.totalCalculatedWeight
      }),
      success: function(data) {
        if (data.error) {
          $('.error-block').removeClass('hidden');
          $('.error-block').html('<p style="color:#e73c3c">' + data.error + '.</p>');
        } else {
          $('.error-block').removeClass('hidden');
          $('.error-block').html('<p class="text-success">Successfully stored weights.</p>');
        }
      }
    })
  }

  get scaleWeightIn() {
    return Number(this.scaleWeightInTarget.value);
  }

  get scaleWeightOut() {
    return Number(this.scaleWeightOutTarget.value);
  }

  get totalCalculatedWeight() {
    return Number(this.totalCalculatedWeightTarget.value);
  }
}
