import { Controller } from "@hotwired/stimulus"

import $ from 'jquery';
import 'jqueryui';
import 'jquery-ui-rotatable';

export default class extends Controller {
  connect() {
    const event = new Event("draggables-connected", { detail: "initial"});
    document.dispatchEvent(event);

    document.addEventListener("editing-on", (e) => {
      this.setupDraggable(e.detail);
      const event = new Event("draggables-connected", { detail: "edited"});
      document.dispatchEvent(event);
    })

    document.addEventListener("editing-off", () => {
      this.stopDraggable();
    })
  }

  setupDraggable(zoom=1) {
    let id = $(this.element).attr('id');
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const position = { x: 0, y: 0 }

    let dx;
    let dy;
    var recoupLeft, recoupTop;

    $(this.element).draggable()
    $(this.element).draggable("destroy")
    $(this.element).draggable({
      drag: function (event, ui) {
        // Fixes dragging with css scale set
        dx = ui.position.left - ui.originalPosition.left;
        dy = ui.position.top - ui.originalPosition.top;
        ui.position.left = ui.originalPosition.left + ( dx/zoom);
        ui.position.top = ui.originalPosition.top + ( dy/zoom );
        ui.position.left += recoupLeft;
        ui.position.top += recoupTop;
      },
      start: function (event, ui) {
        var left = parseInt($(this).css('left'), 10);
        left = isNaN(left) ? 0 : left;
        var top = parseInt($(this).css('top'), 10);
        top = isNaN(top) ? 0 : top;
        recoupLeft = left - ui.position.left;
        recoupTop = top - ui.position.top;
      },
      stop: function(event, ui) {
        let positions = JSON.parse(localStorage.positions || "{}");
        positions[id] = {...positions[id], ...ui.position}
        localStorage.positions = JSON.stringify(positions)

        $.ajax({
          url: '/yard_areas/top_view',
          method: 'POST',
          dataType: 'json',
          data: { yard_areas_top_view: { configuration: positions }},
          beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
          }
        }).done(response => {
          // Notifications that config has been saved
        })
      }
    });

    $(this.element).rotatable({})
    $(this.element).rotatable("destroy")
    $(this.element).rotatable({
      snap: true,
      step: 90,
      wheelRotate: false,
      stop: function(event, ui) {
        let positions = JSON.parse(localStorage.positions || "{}");
        let radians = ui.angle.current;

        // Clockwise Rotation
        // 0     = 0   = → = nc (top side)
        // 0.79  = 45  = ↘ = nc
        // 1.57  = 90  = ↓ = nc (left side)
        // 2.36  = 135 = ↙ = tb rotate 180
        // -3.14 = 180 = ← = all rotate 180 (bottom side)
        // -2.36 = 225 = ↖ = all rotate 180
        // -1.57 = 270 = ↑ = lr rotate 180  (right side)
        // -0.78 = 315 = ↗ = nc
        let normalizedRadians = radians - 2 * Math.PI * Math.floor((radians + Math.PI) / (2 * Math.PI));
        let roundedNormalizedRadians = parseFloat(normalizedRadians.toFixed(2));
        // Rotate row and depth labels
        $(ui.element).find(".row_label,.depth_label,.title_label").css("transform", `rotate(${-normalizedRadians}rad)`)

        // Hide/show yard area labels
        $(ui.element).find(".tb-side,.lr-side").css("visibility", "hidden")

        // Rotate yard area label
        if (roundedNormalizedRadians == -0 ||
            roundedNormalizedRadians == 0.79 ||
            roundedNormalizedRadians == 1.57 ||
            roundedNormalizedRadians == -0.78) {
          $(ui.element).find(".tb-side,.lr-side").css("transform", "")


          if (roundedNormalizedRadians == -0) {
            $(ui.element).find(".top-label").css("visibility", "visible")
          }
          if (roundedNormalizedRadians == 1.57) {
            $(ui.element).find(".lr-side").css("transform", `rotate(180deg)`)
            $(ui.element).find(".left-label").css("visibility", "visible")
          }
        }

        if (roundedNormalizedRadians == 2.36) {
          $(ui.element).find(".tb-side").css("transform", `rotate(180deg)`)
        }

        if (roundedNormalizedRadians == -3.14 || roundedNormalizedRadians == -2.36) {
          $(ui.element).find(".tb-side").css("transform", `rotate(180deg)`)

          if (roundedNormalizedRadians == -3.14) {
            $(ui.element).find(".bottom-label").css("visibility", "visible")
          }
        }

        if (roundedNormalizedRadians == -1.57) {
          $(ui.element).find(".lr-side").css("transform", "")
          $(ui.element).find(".right-label").css("visibility", "visible")
        }



        positions[id]["transform"] = `rotate(${radians}rad)`

        localStorage.positions = JSON.stringify(positions)

        $.ajax({
          url: '/yard_areas/top_view',
          method: 'POST',
          dataType: 'json',
          data: { yard_areas_top_view: { configuration: positions }},
          beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
          }
        }).done(response => {
          // Notifications that config has been saved
        })

      }
    })
  }

  stopDraggable(){
    $(this.element).draggable("destroy")
    $(this.element).rotatable("destroy")
  }
}
