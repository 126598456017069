import { Controller } from "@hotwired/stimulus"

import { useShowFormErrors } from "../mixins/use_show_form_errors"
import { useSelect2 } from "../mixins/use_select2";

import $ from "jquery"

export default class extends Controller {

  // TODO handle disagreements in billedBy and billedPer? ex. time | move,event
  static targets = [
    "tariffType",
    "tariffFilter",
    "eventType",
    "billedBy",
    "billedPer",
    "cargoType",
    "containerStatus",
    "isHazardous",
    "customer",
    "customerType",
    "tariffField",
    "tariffTypeField",
    "singlePrice",
    "ladderedPrice"
  ]

  static values = {
    tariffType: String,
    tariffPriceType: String,
    tariffLadderedPriceEvents: Array,
    tariffLadderedPriceBilledPers: Array,
    tariffEventConfig: {
      value: Object,
      default: {
        eventType: "",
        customerType: "",
        billedPer: "",
      }
    }
  }

  initialize() {
    useSelect2(this)
    useShowFormErrors(this)

    this.tariffFieldTargets.forEach(elem => elem.hidden = true)
  }

  connect() {
    this.updateTariffType()
    this.updateTariff()
    this.updateOptionsFromEventType()
  }

  updateTariff() {
    this.tariffEventConfigValue = {
      eventType: this.eventType,
      customerType: this.customerType,
      billedPer: this.billedPer,
    }
  }

  updateTariffType() {
    this.tariffTypeValue = this.tariffType
  }

  refresh() {
    $(this.eventTypeTarget).val(null).trigger('change');
    $(this.customerTypeTarget).val(null).trigger('change');
    $(this.customerTarget).val(null).trigger('change');

    this.refreshTariffFields();
    this.resetFilters();
  }

  tariffTypeValueChanged() {
    this.tariffTypeFieldTargets.forEach(elem => {
      elem.hidden = !(elem.dataset.tariffTypeValues === undefined || elem.dataset.tariffTypeValues.split(",").includes(this.tariffType))
    });
  }

  tariffPriceTypeValueChanged(value, previousValue) {
    if (value == "laddered") {
      this.singlePriceTarget.hidden = true
      this.ladderedPriceTarget.hidden = false
    } else {
      this.singlePriceTarget.hidden = false
      this.ladderedPriceTarget.hidden = true
    }

    $(this.element).find('input:not([type="hidden"],[type="checkbox"],textarea,select').filter('[data-field-name]').each((i, elem) => {
      let setName = $(elem).attr('data-field-name')
      $(elem).attr('name', setName)
      $(elem).removeAttr('data-field-name')
    })

    $(this.element).find('input:not([type="hidden"],[type="checkbox"],textarea,select').filter(':hidden').each((i, elem) => {
      let getName = $(elem).attr('name')
      $(elem).attr('data-field-name', getName)
      $(elem).removeAttr('name')
    })
  }

  tariffEventConfigValueChanged(value, previousValue) {
    this.refreshTariffFields();

    if (this.currentStorageConfigForLadderedPricing() || this.newStorageConfigForLadderedPricing()) {
      this.tariffPriceTypeValue = "laddered"
    } else {
      this.tariffPriceTypeValue = "single"
    }

    // NOTE train visit carrier still searches shipping lines table :/
    if (!this.customerTarget.dataset.select2AjaxUrl.includes(this.customerType)) {
      let customerParam = ""
      if (this.customerType === "TrainVisitCarrier") {
        customerParam = "ShippingLine"
      } else {
        customerParam = this.customerType
      }

      $(this.customerTarget).val(null).trigger("change")
      this.customerTarget.dataset.select2AjaxUrl = `/autocomplete/customers?customer_type=${customerParam}`
    }
  }

  currentStorageConfigForLadderedPricing() {
    return this.tariffTypeValue == "event"
      && this.tariffLadderedPriceEventsValue.includes(this.eventType)
      && this.tariffLadderedPriceBilledPersValue.includes(this.billedPer)
  }

  newStorageConfigForLadderedPricing() {
    return this.tariffTypeValue == "storage"
      && this.tariffLadderedPriceBilledPersValue.includes(this.billedPer)
  }

  updateOptionsFromEventType() {
    $.ajax({
      url: '/autocomplete/tariff_options',
      dataType: 'json',
      data: {
        event_type: this.eventType,
      }
    }).done(response => {
      this.updateSelect2(this.billedByTarget, response["billed_by"])
      this.updateSelect2(this.billedPerTarget, response["billed_per"])
      this.updateSelect2(this.customerTypeTarget, response["customer_type"])
    })
  }

  refreshTariffFields() {
    this.tariffFieldTargets.forEach(elem => {
      elem.hidden = !(
        // eventType check will apply only when `tariffType === 'event'`
        (this.tariffType !== 'event' || elem.dataset.eventTypeValues === undefined || elem.dataset.eventTypeValues.split(",").includes(this.eventType)) &&
        (elem.dataset.billedPerValues === undefined || elem.dataset.billedPerValues.split(",").includes(this.billedPer)) &&
        (elem.dataset.customerTypeValues === undefined || elem.dataset.customerTypeValues.split(",").includes(this.customerType))
      )
    })
  }

  resetFilters() {
    this.tariffFilterTargets.forEach(elem => {
      $(elem).val(null).trigger('change')
    })

    $(this.isHazardousTarget).prop('checked', false)
  }

  updateOptionsFromCargoType() {
    if (this.cargoType == "vehicle" || this.cargoType == "breakbulk") {
      this.updateSelect2(this.containerStatusTarget, [{ id: "full", text: "Full", value: "full" }])
    } else {
      this.updateSelect2(this.containerStatusTarget, [
        { id: "full", text: "Full", value: "full" },
        { id: "empty", text: "Empty", value: "empty" },
      ])
    }
  }

  get eventType() {
    return this.eventTypeTarget.value
  }

  get cargoType() {
    return this.cargoTypeTarget.value
  }

  get billedPer() {
    return this.billedPerTarget.value
  }

  get customerType() {
    return this.customerTypeTarget.value
  }

  get tariffType() {
    return this.tariffTypeTarget.checked ? "event" : "storage"
  }
}
