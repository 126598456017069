
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).on('shown.bs.modal', () => {
      $(this.element).find('[data-controller="select2"]').each(function() {

        // Getting the Stimulus controller for the element using the global Stimulus instance
        // Here `this` to refer to the current element in the .each loop
        const controller = window.Stimulus.getControllerForElementAndIdentifier(this, 'select2');

        if (controller) {
          // Disconnecting and reconnecting to force reinitialization
          controller.disconnect();
          controller.connect();
        }
      });
    });
  }

  disconnect() {
    // Cleaning up event listeners when this controller is disconnected
    $(this.element).off('shown.bs.modal');
  }
}
