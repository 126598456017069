import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  ///////////////
  /// Actions ///
  ///////////////

  scopeYardAreaId(event) {
    const yardAreaId = event.currentTarget.value;

    // Setting the query params here.
    // Scope the `filter[yard_slot_id]` to list only the slots under `yardAreaId`.
    const existingParams = $('#filter_yard_slot_id').data('params') || {};
    const newParams = {
      ...existingParams,
      ...{ yard_area_id: yardAreaId }
    };

    $('#filter_yard_slot_id').attr('data-params', JSON.stringify(newParams));
  }
}
