import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

export default class extends Controller {
  static values = {
    srcParams: { type: Object, default: {} }
  }

  updateSrc(e) {
    const frameName = e.params.frame
    const frameToUpdate = this.element.getElementsByTagName("turbo-frame").namedItem(frameName)
    const paramName = e.target.getAttribute("name")
    const paramValue = $(e.target).find(':selected').val() || ""

    this.srcParamsValue = {
      ...this.srcParamsValue,
      [frameName]: {
        ...this.srcParamsValue[frameName],
        [paramName]: paramValue
      }
    }

    // Move to value?
    const src = e.params.src
    const params = new URLSearchParams(this.srcParamsValue[frameName])

    frameToUpdate.setAttribute("src", `${src}?${params.toString()}`)
  }
}
