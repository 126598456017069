import { Controller } from "@hotwired/stimulus"

import $ from 'jquery'

export default class extends Controller {
  static values = {
    bay: String,
    crane: String,
    match: { type: Object, default: { "filter": true, "searchFilter": true } }
  }

  filter(filters) {
    if (Object.entries(filters).every(elem => {
      if (elem[1].length > 0) {
        return elem[1].includes(this[`${elem[0]}Value`])
      } else {
        return true
      }
    })) {

      //  Shows items
      let nObject = {...this.matchValue}
      nObject["filter"] =  true
      this.matchValue =  nObject

    } else {

      //  Hides items
      let nObject = {...this.matchValue}
      nObject["filter"] =  false
      this.matchValue =  nObject
    }
  }

  searchFilter(searchRegex) {
    if ( $(this.element).find('td a').html() && $(this.element).find('td a').html().match(searchRegex) ||
        $(this.element).find('td').text() && $(this.element).find('td').text().match(searchRegex) ) {

      //  Shows items
      let nObject = {...this.matchValue}
      nObject["searchFilter"] =  true
      this.matchValue =  nObject
    } else {

      //  Hides items
      let nObject = {...this.matchValue}
      nObject["searchFilter"] =  false
      this.matchValue =  nObject
    }
  }

  matchValueChanged(value, previousValue) {
    if ( this.matchValue["filter"] && this.matchValue["searchFilter"] ) {
      $(this.element).show()
    } else {
      $(this.element).hide()
    }
  }
}
