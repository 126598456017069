var Octopi = window.Octopi || {};

Octopi.BatchActionsModal = (function() {
  function addIdsToForm(formSelector, items, itemToInputAttrs) {
    // Clear out the hidden input fields
    $("input[type='hidden'][name^='bulk_update']").remove();

    items.each(function(){
      $('<input>').attr(itemToInputAttrs(this)).appendTo(formSelector);
    });
  }

  function appendTextValue(selector, appendValue) {
    var newTitle = $(selector).data('title').concat(' (', appendValue, ')');
    $(selector).text(newTitle);
  }

  function updateHiddenAllMatchingOnTheForm(formSelector, applyToAllMatching) {
    $(formSelector + ' > .apply_to_all_filter_matches').val(
      applyToAllMatching
    )
  }

  function updateModalAndSubmitTitles(modalSelector, state) {
    var newTitle;

    if (state['apply_to_all_filter_matches']) {
      newTitle = $('#apply-actions-to-all-matching-results').data('title');
    } else {
      newTitle = state['items'].length;
    }

    appendTextValue(modalSelector.concat('-header'), newTitle);
    appendTextValue(modalSelector.concat('-submit'), newTitle);
  }

  function initializeModal(modalSelector, formSelector, state, itemsToFormInput) {
    updateModalAndSubmitTitles(modalSelector, state['bulk_update']);

    if (state['bulk_update']['apply_to_all_filter_matches']) {
      updateHiddenAllMatchingOnTheForm(formSelector, state['bulk_update']['apply_to_all_filter_matches']);
    } else {
      addIdsToForm(formSelector, state['bulk_update']['items'], itemsToFormInput);
    }

    $(formSelector + ' .js-required-field').on('click', function(event) {
      $(event.target).removeClass('has-error');
    });
  }

  function submitForm(submitButtonSelector, formSelector) {
    var emptyRequiredInputs = $(formSelector + ' .js-required-field:visible').filter(function() {
      // Check the input inside the required field container
      var input = $(this).find('input');
      return input.length > 0 && !input.val();
    });

    if (emptyRequiredInputs.length > 0) {
      emptyRequiredInputs.find('input').addClass('has-error');
    } else {
      $(submitButtonSelector).prop('disabled', true);
      $(formSelector).submit();
    }
  }

  return {
    addIdsToForm: addIdsToForm,
    initializeModal: initializeModal,
    submitForm: submitForm,
    updateHiddenAllMatchingOnTheForm: updateHiddenAllMatchingOnTheForm,
    updateModalAndSubmitTitles: updateModalAndSubmitTitles
  };
}());
