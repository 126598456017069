import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

export default class extends Controller {
  static targets = [
    "container",
    "chassis",
    "damageLocation",
    "vehicle",
    "boat"
  ]

  static values = {
    templateType: String
  }

  connect() {
    this.showTemplate()
  }

  templateTypeValueChanged() {
    this.showTemplate()
  }

  updateTemplateType(e) {
    this.templateTypeValue = $(e.target).find(":selected").data("templateType")
  }

  showTemplate() {
    if (this.hasContainerTarget) {
      $(this.containerTarget).hide()
      $(this.containerTarget).find("input[type='hidden']").prop("disabled", true)
    }

    if (this.hasChassisTarget) {
      $(this.chassisTarget).hide()
      $(this.chassisTarget).find("input[type='hidden']").prop("disabled", true)
    }

    if (this.hasVehicleTarget) {
      $(this.vehicleTarget).hide()
      $(this.vehicleTarget).find("input[type='hidden']").prop("disabled", true)
    }

    if (this.hasBoatTarget) {
      $(this.boatTarget).hide()
      $(this.boatTarget).find("input[type='hidden']").prop("disabled", true)
    }

    if (this.hasTemplateTypeValue) {
      // Dropzone still submits all inputs so we move the "active" input last so it is the value submitted
      $(this[`${this.templateTypeValue}Target`]).appendTo($(this.damageLocationTarget))

      $(this[`${this.templateTypeValue}Target`]).show()
      $(this[`${this.templateTypeValue}Target`]).find("input[type='hidden']").prop("disabled", false)
    }
  }
}
