import { Controller } from "@hotwired/stimulus"

import $ from "jquery";

export default class extends Controller {
  static targets = ["id", "type", "select"];

  initialize() {
    // The CSS selector to target all the select targets of this controller when
    // delegating the global events.
    const selectSelector = "[data-polymorphic-select-target='select']";

    // FIXME: Something about the jQuery events doesn't get picked up by
    // Stimulus and so we need to set up event delegation here instead of using
    // the data-action attribute.
    $(document)
      .on("select2:clear", selectSelector, this.clear.bind(this))
      .on("select2:select", selectSelector, this.update.bind(this));
  }

  clear() {
    this.idTarget.value = null;
    this.typeTarget.value = null;
  }

  update(event) {
    if (event.params && event.params.data) {
      this.idTarget.value = event.params.data.id;
      this.typeTarget.value = event.params.data.type;
    }
  }
}
