import { Controller } from "@hotwired/stimulus"

// NOTE this relies on the jquery object that is being used in application.js to be able to check
// the data object already existing for "DateTimePicker". Once we remove all versions of
// datetimepicker in application js, we can import and use jquery inside the controller directly.
// import $ from "jquery"
// import "eonasdan-bootstrap-datetimepicker"

// Wrapper for our bootstrap datetimepicker
// If using our custom  `date_select` element (initializers/form.rb), you can pass options
// for the datetimepicker, like this:
// f.date_select :date_param, {}, data: { "datetimepicker-options-value": { viewMode: 'years' } }
//
// When adding to a date_field_tag element, it behaves as a typical stimulus controller.
//
// All options for datetimepicker can be found here https://getdatepicker.com/4/

export default class extends Controller {
  static values = {
    options: { type: Object, default: {} }
  }

  connect() {
    $(function() {
      if (typeof $ === "function") {
        if (typeof $(this.element).parent().data("DateTimePicker") === "undefined") {
          if (typeof $(this.element).data("DateTimePicker") === "undefined") {
            $(this.element).datetimepicker(this.optionsValue);
          }
        }
      }
    }.bind(this))
  }

  blockBrowserCalendar(e) {
    e.preventDefault()
  }
}
