var Octopi = window.Octopi || {};

/**
 * BatchActions is a set of functions that manage batch operations state.
 *   The state is stored in data variable that is accessible through the public interface of this class.
 *
 * Selectors: this class maybe used by several different pages.
 *   Try not to introduce any selectors or functions that are only specific for a certain page that will lead to issues.
 *   However, feel free to use any selectors that are defined in _batch_actions.html partial.
 *   That partial is normally shared between pages.
 *  */
Octopi.BatchActions = (function() {
  var state;
  var checkboxesSelector;
  var getSelectedItemState;

  /**
   * Enable batch action button (any button with class .js-batch-action-button).
   * Will not enable buttons that have 'data-always-disable' set to 'true'.
   */
  function enableBatchActionButtons() {
    $(".js-batch-action-button:not([data-always-disable='true'])").prop('disabled', false);
  }

  /**
   * Disable batch action button (any button with class .js-batch-action-button).
   */
  function disableBatchActionButtons() {
    $('.js-batch-action-button').prop('disabled', true);
  }

  /**
   * Adds event handlers on the elements with matching ids.
   * This set of handlers allows keeping in sync state of the table filter header,
   * the "apply all matching" checkbox and the selection checkboxes on the table.
   */
  function initHandlers() {
    $('#select-all-checkbox').on('click', function() {
      var checked = $(this).is(':checked');
      $(checkboxesSelector).prop("checked", checked);

      $('#page-only-items-selected-banner').toggle(checked);
      $('#all-matching-items-selected-banner').hide();
      if (!checked) {
        $('#apply-actions-to-all-matching-results').prop('checked', false);
      }
      update();
    });

    $('#select-all-matching-action').on('click', function() {
      $('#page-only-items-selected-banner').hide();
      $('#all-matching-items-selected-banner').show();
      $('#apply-actions-to-all-matching-results').prop('checked', true);

      // update state that we apply to all matching results
      update(true);
    })

    $('#select-page-only-items-action').on('click', function() {
      $('#page-only-items-selected-banner').show();
      $('#all-matching-items-selected-banner').hide();
      $('#apply-actions-to-all-matching-results').prop('checked', false);

      // update state that we apply only to selected results
      update(false);
    })

    $(checkboxesSelector).on('click', function() {
      if ($(checkboxesSelector).length === $(checkboxesSelector + ':checkbox:checked').length) {
        $('#select-all-checkbox').prop('checked', true);
        $('#page-only-items-selected-banner').show();
      } else {
        $('#select-all-checkbox').prop('checked', false);
        $('#page-only-items-selected-banner').hide();
        $('#apply-actions-to-all-matching-results').prop('checked', false);
        $('.table-info-banner').toggle(false);
      }

      update(false);
    });

    $('#apply-actions-to-all-matching-results').on('click', function() {
      var applyToAllMatching = $(this).is(':checked');

      if (applyToAllMatching) {
        $('#page-only-items-selected-banner').hide();
        $('#all-matching-items-selected-banner').show();
      } else {
        $('.table-info-banner').toggle(false);
      }

      $('#select-all-checkbox').prop('checked', applyToAllMatching);
      $(checkboxesSelector).prop("checked", applyToAllMatching);

      update(applyToAllMatching);
    });
  }

  /**
   * Updates the batch actions UI (buttons) and the state. It will call getSelectedItemState to save items state if it's defined.
   * */
  function update(applyToAllMatching = false) {
    updateState(applyToAllMatching);
    updateBatchActionButtons(applyToAllMatching);
    updateBatchActionHeading(applyToAllMatching);
  }

  function updateState(applyToAllMatching) {
    if (applyToAllMatching) {
      state['bulk_update']['apply_to_all_filter_matches'] = true;
      state['bulk_update']['items'] = [];
    } else {
      var selectedContainers = $(checkboxesSelector + ':checked');
      state['bulk_update']['apply_to_all_filter_matches'] = false;

      if (getSelectedItemState) {
        state['bulk_update']['items'] = getSelectedItemState(selectedContainers);
      }
    }
  }

  function updateBatchActionButtons(applyToAllMatching) {
    var selected = $(checkboxesSelector + ':checked')
    if(applyToAllMatching || selected.length > 0) {
      enableBatchActionButtons();
    } else {
      disableBatchActionButtons();
    }
  }

  function updateBatchActionHeading(applyToAllMatching) {
    var selected = $(checkboxesSelector + ':checked')
    var selectedSet = {}

    var uniqueSelected = selected.filter((n, el) => {
      if (selectedSet[el["id"]]) {
        return false;
      }
      selectedSet[el["id"]] = true;
      return true;
    });

    var batchActionSpan = $('.batch_actions_panel_heading_selected')
    var count = batchActionSpan.data('count').replace('#', uniqueSelected.length);
    var displayText = "(" + (applyToAllMatching ? batchActionSpan.data('all-matching') : count) + ")";

    batchActionSpan.text(displayText);
    if(applyToAllMatching || selected.length > 0) {
      batchActionSpan.css( 'display', 'inline-block' );
    } else {
      batchActionSpan.hide();
    }
  }

  /**
   * Returns the state reference. Can be used to update modals UI based on the current table selection.
   * Don't write directly into the state returned from this function, use  `update` function instead.
   */
  function getState() {
    return state;
  }

  function init(checkboxes, getSelectedItemStateHandler) {
    state = { bulk_update: { items: [], apply_to_all_filter_matches: false } };
    checkboxesSelector = checkboxes;
    getSelectedItemState = getSelectedItemStateHandler;
    initHandlers();
    const batchActionPanel = $('.hidden.batch_actions_panel')[0];
    if (batchActionPanel) {
      batchActionPanel.classList.remove('hidden')
    }
  }

  return {
    init: init,
    update: update,
    getState: getState,
  }
}());
