import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [
    'submitBtn'
  ]

  connect() {
    this.toggleSubmit();
  }

  /////////////////
  //// Actions ////
  /////////////////

  reloadSource(event) {
    const url = new URL(window.location);
    url.searchParams.delete('source_ids[]');
    $(event.currentTarget).val().forEach(recordId => url.searchParams.append('source_ids[]', recordId));

    Turbo.visit(url, { action: 'replace', frame: 'source_records' });
    this.toggleSubmit();
  }

  reloadTarget(event) {
    const url = new URL(window.location);
    url.searchParams.delete('target_id');
    url.searchParams.append('target_id', $(event.currentTarget).val());

    Turbo.visit(url, { action: 'replace', frame: 'target_records' });
    this.toggleSubmit();
  }

  /////////////////
  //// Helpers ////
  /////////////////

  toggleSubmit() {
    const sourceIds = $('#source_ids').val();
    const targetId  = $('#target_id').val();

    if (sourceIds.length === 0 || !targetId) {
      this.submitBtnTarget.setAttribute('disabled', 'disabled');
    } else {
      this.submitBtnTarget.removeAttribute('disabled');
    }
  }
}
