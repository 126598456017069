import { fuzzy_match } from './fuzzy_match'
import { template } from "lodash"

// Sensible defaults for adding some niceties to select2, which include handling html formatted selections
// and fuzzy matching select2 search results
export function select2Defaults(element) {
  if(!element) return;

  const placeholder = element.getAttribute("placeholder") || "Please select"
  const newTagName = element.dataset.select2CreateNew
  const newTagType = element.dataset.select2NewType
  const templateSelectionKey = element.dataset.select2SelectionKey
  // Defaults to 0; Search box is enabled only if the results.size > 0
  // Setting to 'Infinity' or -1, removes the search box.
  const minimumResultsForSearch = element.dataset.select2HideSearchBox === 'true' ? Infinity : 0;
  const dropdownParent = element.dataset.select2DropdownParent;
  let noMatchesText = (element.dataset.select2NoMatchesText || "No matches found").trim();

  // NOTE: Initializing with the data (even if it is using ajax or is passed data from html element
  // config enables using the dataAdapater.convertToOptions function
  const select2Config = {
    debug: true,
    tags: !!newTagName,
    createTag: createTagFactory(newTagType),
    theme: 'bootstrap',
    allowClear: true,
    data: [],
    placeholder: placeholder,
    matcher: matcher,
    sorter: sorter,
    templateResult: createTemplateResultFactory(newTagName),
    templateSelection: createTemplateSelectionFactory(templateSelectionKey),
    minimumResultsForSearch: minimumResultsForSearch,
    dropdownParent: dropdownParent,
    language: {
      noResults: function () {
        return noMatchesText;
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    }
  };

  return {
    ...select2Config,
    ...ajaxConfig(element)
  };
}

function ajaxConfig(element) {
  const ajaxUrl = element.dataset.select2AjaxUrl;
  if(!ajaxUrl) return {};

  let minInputLength = Number(element.dataset.select2MinInputLength);
  if(isNaN(minInputLength)) minInputLength = 2;

  return {
    ajax: {
      url: function() { return element.dataset.select2AjaxUrl; },
      dataType: 'json',
      delay: 250,
      data: function (term) {
        // TODO standardize as one or the other in our controllers
        // Using query here because the default is to send just the term with q, we are opting
        // to send the term object in most other autocomplete actions which I don't understand
        let request_params = {
          query: term.term,
          q: term
        }
        // We can now pass parameters to the autocomplete API's
        // Example "params": {"create": "false"}
        if (element.dataset && element.dataset.params) {
          request_params = {...request_params, ...JSON.parse(element.dataset.params)}
        }
        return request_params
      },
      processResults: function (data, params) {
        if (data['total'] === undefined) {
          return {
            results: data
          }
        } else {
          const { total, ...resultsArray } = data
          return {
            results: Object.values(resultsArray)[0]
          }
        }
      }
    },
    minimumInputLength: minInputLength
  };
}

function matcher (searchObject, optionObject) {
  // Guard againt no search term
  if (searchObject.term === undefined) {
    return { ...optionObject, score: 1 }
  }

  // Make sure empty search term "" doesn't score options differently
  if (searchObject.term === "") {
    return { ...optionObject, highlight: optionObject.text, score: 1 }
  }

  var match = fuzzy_match(
    searchObject.term,
    (optionObject.search && optionObject.search.toString()) ||
    (optionObject.title && optionObject.title.toString()) ||
    optionObject.text.toString()
  )
  return match[0]
    ? { ...optionObject, highlight: match[2], score: match[1] }
    : false
}

function sorter (dataArray) {
  // Score/highlight data returned from ajax (which skips matcher) for consistency
  // Once passed through matcher, data will be an object with score, text, highligh etc. keys
  // or will be false
  const scoredData = dataArray.map(function (el) {
    if (typeof el === 'object') {
      return matcher({ term: $('.select2-search__field').val() }, el)
    } else {
      return el
    }
  })

  return scoredData
    .filter(function (option) {
      return !!option
    })
    .sort((a, b) => b.score - a.score)
}

function createTemplateResultFactory (newTagName) {
   return function templateResult (dataObject) {
    const selectTemplate = template(
      '<div class="octo-select2-selection"> \
                 <p class="title">${term}</p> \
                 <p class="labelrow"> \
                   <span class="label _upcased">NEW ${newTagName}</span> \
                 </p> \
               </div>');


    if (dataObject.loading) {
      return dataObject.text
    } else if (dataObject.highlight !== undefined) {
      // Accommodates autocomplete responses without html text keys
      // Class helps speed up capybara finder for integration testing
      if (dataObject.newTag) {
        const selectHighlight = selectTemplate({term: dataObject.highlight, newTagName: newTagName})

        return $(`<div class="autocomplete-result">${selectHighlight}<div>`)
      } else {
        let highlight = dataObject.text.replace(
          dataObject.search || dataObject.title || dataObject.text,
          dataObject.highlight
        )

        return $(`<div class="autocomplete-result">${highlight}<div>`)
      }
    } else {
      return $(dataObject.text)
    }
  }
}

function createTemplateSelectionFactory (templateSelectionKey) {
  return function templateSelection (dataObject) {

    // Set all attr/props from response data onto options
    Object.entries(dataObject).forEach(([key, value]) => {
      if (key === "id" || key === "text" || key == "title" ) {
        return
      } else {
        dataObject.element.dataset[key] = value
      }
    })

    if (dataObject.loading) {
      return dataObject.text
    } else {
      // NOTE Passing the jQuery object alone prevents the placeholder from being displayed
      // so we only pass the text of any json option
      return (templateSelectionKey && $(dataObject[templateSelectionKey]).text()) ||
        dataObject.title || dataObject.text
    }
  }
}

function createTagFactory (newTagType) {
  return function createTag (dataObject) {
    var term = $.trim(dataObject.term);

    if (term === '') {
      return null;
    }

    // Guard against non numbers
    if (newTagType === 'number' && !term.match(/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/)) {
      return null;
    }

    return {
      id: term,
      text: term,
      title: term,
      newTag: true
    }
  }
}

