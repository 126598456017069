function OctoAlerter(options) {
  this.text = options.text
  this.alertType = options.type
  this.position = options.position
  this.alertId = options.id ? options.id : "octo-alert";
  this.elementClass = options.type === 'dynamic' ? 'warning' : options.type

  this.setDismissBtn(options.dismissable)
  this.setModalTitle(options)
  this.onClose = options.onClose
}

OctoAlerter.prototype = {
  setDismissBtn: function (dismissable) {
    this.dismissBtn = dismissable ? "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" : ''
  },

  setModalTitle: function (options) {
    if (options.modalTitle) {
      this.modalTitle = options.modalTitle
    } else {
      this.modalTitle = options.type === 'dynamic' ? 'Warning!' : options.type + "!";
    }
  },

  showOctoAlertModal: function () {
    var self = this;
    var modalTemplate = "<div class='modal fade octo-alert-modal " +
                         this.elementClass +
                         "' id='alertModal' tabindex='-1' role='dialog' aria-labelledby='alertModalLabel'> <div class='modal-dialog' role='document'><div class='modal-content'> <div class='modal-header'> <button type='button' class='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button> <h4 class='modal-title' id='alertModalLabel'>" +
                         this.modalTitle +
                         "</h4></div><div class='modal-body'>" +
                         this.text +
                         "</div><div class='modal-footer'><button type='button' class='btn btn-default' data-dismiss='modal'>OK</button></div></div></div></div>";

    $('body').append(modalTemplate);
    $('#alertModal').modal('show');
    $('#alertModal').on('hidden.bs.modal', function (e) {
      $(this).remove();
      if (typeof self.onClose == 'function') {
        self.onClose.call(self);
      }
    });
  },

  showOctoAlertInline: function () {
    var selector = this.position ? this.position : '#app-content'
    var alertIdSel = '#' + this.alertId
    var inlineTemplate = "<div id='" +
                          this.alertId +
                          "' class='alert octo-alert alert-" +
                          this.elementClass +
                          "' role='alert'>" +
                          this.dismissBtn +
                          ' ' +
                          this.text +
                          "</div>"

    if (this.position) {
      $(selector).first().append(inlineTemplate)
    } else {
      $(selector).prepend(inlineTemplate)
    }
    if ($(alertIdSel).length) {
      // Roll screen to the top to show alert
      $('html, body').animate({
        scrollTop: $(alertIdSel).offset().top - 50
      }, 250)
    }
  }
}

function parameterizeString (string) {
  return string.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, '').replace(/\s/g, '-');
}

function octoAlert (options) {
  var alerter = new OctoAlerter(options)

  if (options.alertIsModal) {
    alerter.showOctoAlertModal(options)
  } else if (options.type === 'dynamic') {
    // For Dynamic type a modal will be shown once and then
    // Octopi will show a flash message in the top of the page
    var alertSeen = localStorage.getItem('seen_alrt_' + parameterizeString(options.text))
    if (alertSeen) {
      alerter.showOctoAlertInline(options)
    } else {
      localStorage.setItem('seen_alrt_' + parameterizeString(options.text), true);
      alerter.showOctoAlertModal(options)
    }
  } else {
    alerter.showOctoAlertInline(options);
  }
}
