import { Controller } from "@hotwired/stimulus"

// TODO: Remove this at some point
// Needed for Custom Seals for Naportec
// https://github.com/cetuslabs/octopi/issues/21509
const NAPORTEC_ID = 461

export default class extends Controller {
  /////////////////
  // Initializer //
  /////////////////

  connect() {
    $('.hide-until-container-selected').hide();

    const containerDropdown = document.querySelector('#movement_container_id');
    containerDropdown.dispatchEvent(new Event('select2:change', { bubble: true }));

    this.drawLocation = $('.panel .panel-body').first();
  }

  ///////////////
  /// Actions ///
  ///////////////

  drawContainerMetadata(event) {
    const containerId = event.target.value;
    if(!containerId) return;

    $.get(`/containers/${containerId}/basics.json`)
      .done(function(data) {
        $('.hide-until-container-selected').show();
        if(data.container.organization_id !== NAPORTEC_ID) $('#customs-seals').hide();

        drawContainerMetadataDiv(
          data.container,
          data.container.consignees.map(consignee => consignee.name)
        );
      })
      .fail(function() {
        $('.hide-until-container-selected').hide();
        removeContainerMetadataDiv();

        console.error(`Couldn't fetch ${containerId}`);
      })
  }

  updateServiceOrderSelect2Params(event) {
    const containerId = event.target.value;
    if(!containerId) return;

    const serviceOrderDropdown = $('#service_order_id');

    // Clear the SO options;
    serviceOrderDropdown.empty();

    // Setting the query params here.
    const existingParams = serviceOrderDropdown.data('params') || {};
    const newParams = {
      ...existingParams,
      ...{
        container_id: containerId
      }
    };

    serviceOrderDropdown.attr('data-params', JSON.stringify(newParams));
  }
}
