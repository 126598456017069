import { Controller } from "@hotwired/stimulus"
// ideally we import bootstrap modal, and it handles a few things for us
// like closing the modal when clicking buttons or clicking outside
// if we do that, somehow the container index page modals start to autoclose
// let uncomment this once we figure it out
// import 'bootstrap/js/modal'

/*
USAGE: (example.html.slim)
/ This controller can handle one or multiple modals per page.
/ Use unique names for each modal if you have more than one.
#example(data-controller="batch-actions-modal")
  ...
  .modal.fade(data-batch-actions-modal-target="modalName")
    .modal-content
      = t('.content')
  ...
  / Button to open the modal
  = button_tag t('.name'),
    data: { batch-actions-modal-target: "modalName", action="batch-actions-modal#showModal"}
*/
export default class extends Controller {
  static targets = []

  // To open a modal you need to provide the modalName
  // as shown in the usage example above.
  // This initializes the modal
  showModal(event) {
    event.preventDefault()
    const modalName = event.target.dataset.batchActionsModalTarget
    const queueSize = event.target.dataset.queueSize
    const modal = `#${modalName}`
    const modalHeader = `#${modalName}-header`
    const modalTitle = $(modalHeader).text()
    const form = `form#${modalName}-form`
    const submitButton = `#${modalName}-submit`

    // Submits the form when submit button is clicked
    $(submitButton).on('click', () => {
      $(form).submit()
    })

    // Shows the modal
    $(modal).modal("show")

    // Update the modal to show the number of containers
    $(modal).on('show.bs.modal', function () {
      $(modal).find(modalHeader).text(`${modalTitle} (${queueSize})`)
      $(modal).find(submitButton).text(`${modalTitle} (${queueSize})`)
    })
  }
}
