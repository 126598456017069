export default {
  initialize: function(model){
    return {
      model: model,
      version: 0,
      changes: []
    }
  },

  insert: function(history, newElements, position){
    if(!newElements.length) return history
    if(position == undefined) position = history.model.length
    if(!Array.isArray(newElements)) newElements = [newElements]
    var newModel = [
      ...history.model.slice(0, position),
      ...newElements,
      ...history.model.slice(position)
    ]

    return {
      model: newModel,
      version: history.version + 1,
      changes: history.changes.slice(0, history.version).concat({
        type: 'insert',
        positions: newElements.map(function(_, index){ return index + position }),
        elements: newElements
      })
    }
  },

  remove: function(history, positions){
    if(!Array.isArray(positions)) positions = [positions]
    if(!positions.length) return history

    return {
      model: history.model.filter(function(_, index){
        return !positions.includes(index)
      }),
      version: history.version + 1,
      changes: history.changes.slice(0, history.version).concat({
        type: 'remove',
        positions: positions,
        elements: history.model.filter(function(_, index){
          return positions.includes(index)
        }),
      })
    }
  },

  current: function(history){
    return history.model
  },

  undo: function(history) {
    if(this.undosLeft(history) <= 0) return history
    var newModel, change = history.changes[history.version - 1]

    if(change.type == 'insert'){
      newModel = this.remove(history, change.positions).model
    } else if(change.type == 'remove'){
      newModel = change.positions.reduce(function(finalHistory, position, index){
        return this.insert(finalHistory, change.elements[index], position)
      }.bind(this), history).model
    }

    return {
      model: newModel,
      version: history.version - 1,
      changes: history.changes
    }
  },

  redo: function(history) {
    var newModel, change = history.changes[history.version]

    if(change.type == 'insert'){
      newModel = this.insert(history, change.elements, change.positions).model
    } else if(change.type == 'remove') {
      newModel = this.remove(history, change.positions).model
    }

    return {
      model: newModel,
      version: history.version + 1,
      changes: history.changes
    }
  },

  undosLeft: function(history){
    return history.version
  },

  redosLeft: function(history){
    return history.changes.length - history.version
  }
}
