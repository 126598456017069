import Vue from 'vue'
import Vuex   from 'vuex'
Vue.use(Vuex)

import axios  from 'axios'
import qs     from 'qs'
import VesselPlanItem from 'modules/vessel_plan_item'

import "regenerator-runtime/runtime"
import createPersistedState from "vuex-persistedstate"
import Pusher from 'pusher-js'

const token = (document.querySelector('meta[name="csrf-token"]') || {}).content
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.paramsSerializer = function(params) { return qs.stringify(params) };

window.addEventListener('storage', (changes) => {
  if(changes.key !== 'vuex') return
  store.commit('UPDATE_STATE_FROM_OTHER_TAB', JSON.parse(changes.newValue))
})

const storeOptions = {
  plugins: [
    createPersistedState({
      paths: [
        'yardAreasGridColorBy',
        'tutorialPath',
        'showTutorialPopup',
        'sidebarScrollTop',
        'rightSectionOpen',
        'queue',
        'queueSelectedIndex'
      ],
      filter: mutation => mutation.type != 'UPDATE_STATE_FROM_OTHER_TAB'
    })
  ],

  mutations: {
    UPDATE_STATE_FROM_OTHER_TAB: (state, newState) => {
      const org = state.currentOrganization.id
      state.queue[org] = [...newState.queue[org] || []]
      Object.assign(state, newState)
    },

    LOADING_STATE_ON:  state => state.loading = true,
    LOADING_STATE_OFF: state => state.loading = false,
    UPDATE_YARD_AREAS_GRID_COLOR_BY: (state, index) => state.yardAreasGridColorBy = index,
    UPDATE_QUEUE: (state, queue) => {
      const org = state.currentOrganization.id
      state.queue = {
        ...state.queue,
        [org]: queue.filter(container => Boolean(container.number))
                    .filter(({ number: n1 }, index, containers) => {
                      // Basically this is removing duplicates by container number
                      return index == containers.findIndex(({ number: n2 }) => n1 == n2)
                    })
      }
    },
    UPDATE_QUEUE_SELECTED_INDEX: (state, index) => state.queueSelectedIndex = index,
    START_SOCKET: (state) => state.pusher = new Pusher(process.env.PUSHER_KEY, { encrypted: true }),
    UPDATE_TUTORIAL_PATH: (state, newPath) => state.tutorialPath = newPath,
    TOGGLE_RIGHT_SECTION: state => state.rightSectionOpen = !state.rightSectionOpen,
    HIDE_RIGHT_SECTION: state => state.rightSectionOpen = false,
    SHOW_RIGHT_SECTION: state => state.rightSectionOpen = true,
    HIDE_TUTORIAL_POPUP: state => state.showTutorialPopup = false,
    UPDATE_SIDEBAR_SCROLL_TOP: (state, offset) => state.sidebarScrollTop = offset
  },

  state: {
    loading: false,
    queue: {},
    queueSelectedIndex: 0,
    currentOrganization: (window.Octopi || {}).organization,
    tutorialPath: [{ location: 'topics' }],
    rightSectionOpen: true,
    showTutorialPopup: true,
    sidebarScrollTop: 0
  },

  getters: {
    autocompleteAjaxFn: () => (resource) => (options, success) => {
      jQuery.ajax(options).then(success)
    },

    currentContainersQueue: state => {
      if(!state.currentOrganization) return []
      const id = state.currentOrganization.id
      return state.queue[id] || []
    },

    currentContainersQueueNumbers: (_, getters) => {
      return getters.currentContainersQueue.map(container => container.number)
    }
  },

  actions: {
    async addToContainersQueue({ commit, getters }, container){
      if(!container.number) return
      if(getters.currentContainersQueueNumbers.includes(container.number)) return
      const newContainer = { ...container }
      newContainer.weight = container.weight_mt || 'N/A'
      newContainer.vpi_attributes = VesselPlanItem.fromContainer(container)
      commit('UPDATE_QUEUE', [...getters.currentContainersQueue, newContainer])
    },

    async addMultipleContainersToQueue({ dispatch }, containers){
      for (const container of containers){
        await dispatch('addToContainersQueue', container)
      }
    },

    async removeFromContainersQueue({ commit, getters, state }, container){
      if(!container.number) return
      const index    = state.queueSelectedIndex
      const newQueue = getters.currentContainersQueue
                              .filter(c => c.number !== container.number)
      commit('UPDATE_QUEUE', newQueue)
      commit('UPDATE_QUEUE_SELECTED_INDEX', Math.max(Math.min(newQueue.length - 1, index), 0))
    },

    async clearContainersQueue({ commit, getters }) {
      commit('UPDATE_QUEUE', getters.currentContainersQueue.filter(c => c.number === ''))
    },

    async selectContainerOnQueue({ commit, getters }, container) {
      const index = getters.currentContainersQueueNumbers
                           .findIndex(n => n == container.number)
      commit('UPDATE_QUEUE_SELECTED_INDEX', Math.max(index, 0))
    },

    async addBreakbulkToYardHeap({ dispatch, commit }, { breakbulk, yardSlotId }){
      commit('LOADING_STATE_ON')
      await dispatch('put', {
        url: `/yard_areas/containers/${breakbulk.id}.json`,
        container: { yard_slot_id: yardSlotId }
      })
      commit('LOADING_STATE_OFF')
    },

    async addContainerToYardHeap({ dispatch, commit }, { container, yardSlotId }){
      commit('LOADING_STATE_ON')
      await dispatch('put', {
        url: `/yard_areas/containers/${container.id}.json`,
        container: { yard_slot_id: yardSlotId },
      })
      commit('LOADING_STATE_OFF')
    },

    async addBreakbulkToYardWarehouse({ dispatch, commit }, { breakbulk, yardSlot }){
      commit('LOADING_STATE_ON')
      await dispatch('moveContainerToYardSlot', { container: breakbulk, yardSlot })
      commit('LOADING_STATE_OFF')
    },

    async addContainerToYardGrid({ dispatch, commit }, { container, yardSlot }){
      commit('LOADING_STATE_ON')
      await dispatch('moveContainerToYardSlot', { container, yardSlot })
      commit('LOADING_STATE_OFF')
    },

    async removeBreakbulkFromYardHeap({ dispatch, commit }, { id }){
      commit('LOADING_STATE_ON')
      await dispatch('put', { url: `/yard_areas/containers/${id}.json`, container: { yard_slot_id: '' } })
      commit('LOADING_STATE_OFF')
    },

    async removeContainerFromYardHeap({ dispatch, commit }, { id }){
      commit('LOADING_STATE_ON')
      await dispatch('put', { url: `/yard_areas/containers/${id}.json`, container: { yard_slot_id: '' } })
      commit('LOADING_STATE_OFF')
    },

    async removeBreakbulkFromYardWarehouse({ dispatch, commit }, { id }){
      commit('LOADING_STATE_ON')
      await dispatch('put', { url: `/yard_areas/containers/${id}.json`, container: { yard_slot_id: '' } })
      commit('LOADING_STATE_OFF')
    },

    async removeContainerFromYardGrid({ dispatch, commit }, { id }){
      commit('LOADING_STATE_ON')
      await dispatch('put', { url: `/yard_areas/containers/${id}.json`, container: { yard_slot_id: '' } })
      commit('LOADING_STATE_OFF')
    },

    async moveContainerToYardSlot({ state, commit }, { container, yardSlot = {}, lifts = [], workQueueLiftType }){
      if(!container) {
        console.warn(`There is no container to move to yard slot ${yardSlot.custom_name}!`)
        return
      }

      commit('LOADING_STATE_ON')
      await jQuery.post('/movements/internal_movements.json', {
        container_id: container.id,
        yard_slot_id: (yardSlot || {}).id,
        work_queue_lift_type_id: (workQueueLiftType || {}).id,
        lifts: lifts
      })
      Promise.resolve()
      commit('LOADING_STATE_OFF')
    },

    async createWorkQueueItem({ commit, dispatch }, {
      workQueueId,
      containerId,
      source = {id, type},
      destination = {id, type}
    }){
      commit('LOADING_STATE_ON')

      await dispatch('post', {
        'url': '/work_queue_items.json',
        'work_queue_item': {
          'work_queue_id': workQueueId,
          'container_id': containerId,
          'source_id': source.id,
          'source_type': source.type,
          'destination_id': destination.id,
          'destination_type': destination.type
        }
      })

      commit('LOADING_STATE_OFF')
    },

    async moveChassisToYardSlot({ dispatch, commit }, { chassis, yardSlot }){
      if(!chassis) {
        console.warn(`There is no chassis to move to yard slot ${yardSlot.custom_name}!`)
        return
      }

      commit('LOADING_STATE_ON')

      await dispatch('post', {
        url: `/chassis/${chassis.id}/yard_area_relocations.json`,
        chassis: {
          yard_slot_id: (yardSlot || {}).id
        }
      });
      commit('LOADING_STATE_OFF')
    },

    async addChassisToYard({ dispatch, commit }, { chassis, yardSlot, yardArea }){
      commit('LOADING_STATE_ON');
      // TODO: Yet to be implemented API
      await dispatch('put', {
        url: `/yard_areas/chassis/${chassis.id}.json`,
        chassis: {
          yard_slot_id: (yardSlot || {}).id,
          yard_area: (yardArea || {}).id
        }
      });
      commit('LOADING_STATE_OFF');
    },

    async removeChassisFromYard({ dispatch, commit }, { id }){
      commit('LOADING_STATE_ON');
      // TODO: Yet to be implemented API
      await dispatch('put', {
        url: `/yard_areas/chassis/${id}.json`,
        chassis: { yard_slot_id: '' }
      });
      commit('LOADING_STATE_OFF');
    },

    async saveColor(_, { key, color }){
      return jQuery.post('/color_preferences.json', { color_preference: { key, color } })
    },

    async deleteColor(_, { key }){
      return jQuery.get(`/color_preferences/${key}/.json`, { color_preference: { key }, _method: 'DELETE' })
    },

    async get(_, { url, ...params }){
      const { data } = await axios({
        url,
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        params: params
      })
      return data
    },

    async post(_, { url, formData, ...params }){
      if(formData) params = formData
      const { data } = await axios({ url, data: params, method: 'POST' })
      return data
    },

    async put(_, { url, ...params }){
      const { data } = await axios({ url, data: params, method: 'PUT' })
      return data
    },

    async delete(_, { url, ...params }){
      const { data } = await axios({ url, data: params, method: 'DELETE' })
      return data
    },

    async recordFullstoryEvent(_, { event, ...metadata }){
      if(window.FS) return FS.event(event, metadata)
      console.log(`FS event was triggered but not recorded: ${event}`, metadata)
    },

    async bindSocketEvents({ state, commit }, { channel: channelName, ...events }) {
      if(!state.pusher) commit('START_SOCKET')
      if(!state.pusher.channel(channelName)) state.pusher.subscribe(channelName)
      const channel = state.pusher.channel(channelName)
      Object.entries(events)
            .forEach(([event, callback]) => channel.bind(event, callback))
    },

    async bindUserSocketEvents({ dispatch }, events) {
      const channel = Octopi.userSocketChannel
      if(!channel) return
      dispatch('bindSocketEvents', { channel, ...events })
    },

    // Public: Triggers a synchronous request (AKA the page will reload).
    // This function works as a VueJS compatible way of the Rails "data-method"
    // attribute in HTML elements.
    //
    // Essentially, because Rails sticks to REST as much as it can, sometimes
    // a simple link should trigger a POST, PUT or even DELETE requests. PUT and
    // DELETE requests are not even possible within HTML, so Rails uses jQuery
    // UJS library, which will intercept those elements, create a fake hidden
    // form and then programatically trigger submit.
    //
    // This function does that. It also serves to simply navigate to a different
    // URL. jQuery AJAX function used to provide that by allowing to pass
    // `{ async: false }` to the options, but it got deprecated.
    async navigate(_, { path, method = 'GET', params = {} } = {}){
      let form = document.createElement('form')
      let url = new URL(`${location.protocol}//${location.host}${path}`)
      Object.entries(params).forEach(([name, value]) => url.searchParams.append(name, value))
      form.action = url.toString()
      form.method = 'POST'
      form.style.display   = 'none'
      let methodInput      = document.createElement('input')
      methodInput.name     = '_method'
      methodInput.value    = method
      let csrfTokenInput   = document.createElement('input')
      csrfTokenInput.name  = 'authenticity_token'
      csrfTokenInput.value = token
      form.append(methodInput)
      if(method != 'GET') {
        form.append(csrfTokenInput)
      }
      document.body.append(form)
      form.submit()
    }
  }
}

const createStore = (beforeCreate = options => options) => {
  return new Vuex.Store(beforeCreate(storeOptions))
}

export { createStore }
export default createStore()
