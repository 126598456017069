import { Controller } from "@hotwired/stimulus"

import { useSelect2 } from "../mixins/use_select2";

import $ from 'jquery'

require("../../assets/javascripts/octo_alert")

export default class extends Controller {
  static targets = [
    "currency",
    "customer",
    "lineTotal",
    "tariff",
    "total"
  ]

  static values = { tariffData: Array }

  connect() {
    // Allows updating the select2 target
    useSelect2(this)

    this.runTariffAjax()
  }

  // Actions
  runTariffAjax() {
    $.ajax({
      url: '/autocomplete/tariffs',
      dataType: 'json',
      data: {
        customer: this.customer(),
        currency: this.currency()
      }
    }).done(response => {
      this.tariffDataValue = response

      if (response.length < 1) {
        octoAlert({
          text: 'No tariffs found for this customer and currency.',
          type: 'warning',
          dismissable: true
        })
      }

      this.updateTariffs(response)
    })
  }

  updateTariffs(response) {
    this.tariffTargets.forEach(tariff => {
      this.updateSelect2(tariff, response)
    })
  }

  clearTariffs() {
    this.tariffTargets.forEach(tariff => {
      // TODO Trigger event that line items controller will handle
      $(tariff)
        .empty()       // Removes tariff options
        .prop("disabled", true)
        .trigger("select2:clear")
        .trigger("select2:revert")
    })
  }

  handleFormChange() {
    this.updateAddedSelectField()
    this.calculateInvoice()
  }

  updateAddedSelectField() {
    // Add tariff options to newly added field
    let newTariff = this.tariffTargets.slice(-1)

    if (this.customerTarget.value) {
      this.updateSelect2(newTariff, this.tariffDataValue)
    } else {
      $(newTariff).prop("disabled", true)
    }
  }

  calculateInvoice() {
    const lineTargets = $(this.lineTotalTargets).filter(":visible")
    const lineTotals = lineTargets.map((i,e) => parseFloat($(e).text()))

    const total = [...lineTotals].reduce((output, lineTotal) => {
      return output += (lineTotal || 0)
    }, 0)

    this.totalTarget.innerHTML = parseFloat(total).toFixed(2)
  }

  // Helper methods
  currency() {
    return this.currencyTarget.value
  }

  customer() {
    return this.customerTarget.value
  }
}
