import { Controller } from "@hotwired/stimulus"

import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "invoice",
    "invoiceNumber",
    "invoiceCustomer",
    "invoiceTotal",
    "invoiceBalance",
    "invoiceCurrency"
  ]

  connect() {
    $(this.invoiceTarget).on("select2:close", (e) => {
      if ($(this.invoiceTarget).find(":selected").val() !== "") {
        // Update invoice info labels
        const invoiceNumber =  $(this.invoiceTarget).find(":selected").data("invoiceNumber")
        const invoiceCustomer =  $(this.invoiceTarget).find(":selected").data("invoiceCustomer")
        const invoiceTotal = $(this.invoiceTarget).find(":selected").data("invoiceTotal")
        const invoiceBalance = $(this.invoiceTarget).find(":selected").data("invoiceBalance")
        const invoiceCurrency = $(this.invoiceTarget).find(":selected").data("invoiceCurrency")

        this.invoiceNumberTarget.innerHTML = invoiceNumber;
        this.invoiceCustomerTarget.innerHTML = invoiceCustomer;
        this.invoiceTotalTarget.innerHTML = invoiceTotal;
        this.invoiceBalanceTarget.innerHTML = invoiceBalance;
        this.invoiceCurrencyTarget.innerHTML = invoiceCurrency;

      } else {
        this.invoiceNumberTarget.innerHTML = "";
        this.invoiceCustomerTarget.innerHTML = "";
        this.invoiceTotalTarget.innerHTML = "";
        this.invoiceBalanceTarget.innerHTML = "";
        this.invoiceCurrencyTarget.innerHTML = "";
      }
    })
  }

}
