import { Controller } from "@hotwired/stimulus"

import { useShowFormErrors } from '../mixins/use_show_form_errors'
import { useSelect2 } from "../mixins/use_select2";

import { template } from "lodash"
import $ from "jquery"

export default class extends Controller {
  static targets = [
    "manualDescription",
    "tariffDescription",
    "lineItemId",
    "lineTotal",
    "price",
    "priceTier",
    "priceTierId",
    "billedPerQuantity",
    "billedByQuantity",
    "subtotal",
    "tariff",
    "tariffDescriptionWrapper",
    "tax",
    "taxRate",
    "manualTaxRate",
    "checkbox",
    "tariffInfo",
    "rateLabel",
    "priceLabel",
    "tariffLink",
    "tagTemplate",
    "linkTemplate",
    "selectTemplate"
  ]

  connect() {
    useSelect2(this)
    useShowFormErrors(this)

    this.setTaxRateFromTariff()
    this.toggleSubmittedFields()
    this.addSelect2EventListeners()
  }

  addSelect2EventListeners() {
    $(this.tariffTarget).on("select2:revert", (e) => {
      this.revertToSelect()
    })

    $(this.tariffTarget).on("select2:change", (e) => {
      this.updateLineFromTariffChange()
    })

    $(this.tariffTarget).on("select2:clear", (e) => {
      this.clearPrices()
      this.clearPriceLabel()
    })

    $(this.tariffTarget).on("select2:init", (e) => {
      if (this.lineItemIdTarget.value) {
        this.swapToDescription()
        // Trigger textarea resize
        $(this.tariffDescriptionTarget).trigger("input")
        $(this.manualDescriptionTarget).trigger("input")
      } else {
        this.revertToSelect()
      }

      this.updateSelectionOptions()
    })

    // select2:select event doesn't fire if you select the same item :/
    // https://github.com/select2/select2/issues/5229
    // So we are using select2:close and guarding against clearing as select2:select
    $(this.tariffTarget).on("select2:close", (e) => {
      if ($(this.tariffTarget).find(":selected").val() !== "") {
        this.swapToDescription()
        // Trigger oninput of textarea
        $(this.tariffDescriptionWrapperTarget).find("textarea").val($(this.tariffTarget).find(":selected").data("description")).trigger("input")

        // Update edit link
        const linkTemplate = template(this.linkTemplateTarget.innerHTML);
        const linkPath = $(this.tariffTarget).find(":selected").data("tariffPath")

        $(this.tariffLinkTarget).html(linkTemplate({link: linkPath, linkText: "Edit Tariff"}))

        // Change price rate label
        const tagTemplate = template(this.tagTemplateTarget.innerHTML);
        const rateLabel = $(this.tariffTarget).find(":selected").data("rateLabel")

        $(this.rateLabelTarget).html(tagTemplate({tagContent: rateLabel}))
      }
    })

    $(this.priceTierTarget).on("select2:close", (e) => {
      if ($(this.priceTierTarget).find(":selected").val() !== "") {

        this.clearPriceLabel()

        if ($(this.priceTierTarget).find(":selected").data("select2Tag")) {

          // Remove price tier if set to custom price
          $(this.priceLabelTarget).html("<span class='label label-default'>NEW PRICE</span>")
          $(this.priceTierIdTarget).val(null)
        } else {

          // Set price tier id hidden field
          const priceTierId = $(this.priceTierTarget).find(":selected").data("priceTierId")
          $(this.priceTierIdTarget).val(priceTierId)

          // Update labels
          // TODO move setting labels to dedicated function
          const tagTemplate = template(this.tagTemplateTarget.innerHTML);
          const priceLabel = $(this.priceTierTarget).find(":selected").data("priceLabel")
          $(this.priceLabelTarget).html(tagTemplate({tagContent: priceLabel}))
        }

        this.calculate()
      }
    })
  }

  updateSelectionOptions() {
    const selectTemplate = template(this.selectTemplateTarget.innerHTML);
    const rateLabel = $(this.tariffTarget).find(":selected").data("rateLabel")
    $(this.priceTierTarget.options).each((i, option) => {
      const priceLabel = $(option).data("priceLabel")
      option.text = selectTemplate({
        price: option.text,
        priceLabel: priceLabel,
        rateLabel: rateLabel
      })
    })
  }

  toggleLineFields() {
    this.toggleSubmittedFields()
    this.calculate()
  }

  toggleSubmittedFields() {
    // Set field name
    $(this.element).find('[data-field-name]').each((i,e) => {
      $(e).attr('data-field-name')
      $(e).prop('name', $(e).attr('data-field-name'))
    })

    // Tax rate
    $(this.manualTaxRateTarget).filter(":hidden").attr('data-field-name', $(this.taxRateTargets).filter(":hidden").prop("name"))
    $(this.manualTaxRateTarget).filter(":hidden").prop("name", "")

    // Description
    $(this.descriptionTargets).filter(":hidden").attr('data-field-name', $(this.descriptionTargets).filter(":hidden").prop("name"))
    $(this.descriptionTargets).filter(":hidden").prop("name", "")

    // Manual line item
    if (this.checkboxTarget.checked) {
      $(this.tariffTarget).filter(":hidden").attr('data-field-name', $(this.tariffTarget).filter(":hidden").prop("name"))
      $(this.tariffTarget).filter(":hidden").prop("name", "")

      $(this.priceTierTarget).filter(":hidden").attr('data-field-name', $(this.priceTierTarget).filter(":hidden").prop("name"))
      $(this.priceTierTarget).filter(":hidden").prop("name", "")

      $(this.tariffDescriptionTarget).attr('data-field-name', $(this.tariffDescriptionTarget).prop("name"))
      $(this.tariffDescriptionTarget).prop("name", "")
    // Tariff line item
    } else {
      $(this.priceTarget).filter(":hidden").attr('data-field-name', $(this.priceTarget).filter(":hidden").prop("name"))
      $(this.priceTarget).filter(":hidden").prop("name", "")

      $(this.manualDescriptionTarget).attr('data-field-name', $(this.manualDescriptionTarget).prop("name"))
      $(this.manualDescriptionTarget).prop("name", "")
    }
  }

  swapToDescription() {
    $(this.tariffTarget).data("select2").$container.hide()
    $(this.tariffDescriptionWrapperTarget).show()
  }

  revertToSelect() {
    $(this.tariffTarget).data("select2").$container.show()
    $(this.tariffDescriptionWrapperTarget).hide()
  }

  revertToSelectFromClick() {
    this.revertToSelect()
    $(this.tariffTarget).select2("open")
  }

  updateLineFromTariffChange() {
    this.setTaxRateFromTariff()
    this.clearPrices()
    this.clearPriceLabel()
    this.runPriceTierAjax()
  }

  setTaxRateFromTariff() {
    this.taxRateTarget.innerHTML = isNaN(parseInt($(this.tariffTarget).find(":selected").data("taxRate"))) ? 0 : $(this.tariffTarget).find(":selected").data("taxRate")
  }

  runPriceTierAjax() {
    // Cache response on data oject/window?
    window.priceCache = window.priceCache || new Map
    let priceCache = window.priceCache

    let tariffId = $(this.tariffTarget).find(":selected").val()

    if (tariffId === "") {
      return
    } else if (priceCache.has(tariffId)) {

      let response = priceCache.get(tariffId)

      this.updatePriceTiers(response)
    } else {
      $.ajax({
        url: '/autocomplete/price_tiers',
        dataType: 'json',
        data: {
          tariff_id: $(this.tariffTarget).find(":selected").val()
        }
      }).done(response => {
        const interpolatedResponse = this.interpolateTemplate(response)
        priceCache.set(tariffId, interpolatedResponse)
        this.updatePriceTiers(interpolatedResponse)
      })
    }
  }

  interpolateTemplate(response) {
    const selectTemplate = template(this.selectTemplateTarget.innerHTML);
    const rateLabel = $(this.tariffTarget).find(":selected").data("rateLabel")

    return response.map(priceTierResponse => {
      const priceLabel = priceTierResponse.priceLabel
      const selectHtml = selectTemplate({price: priceTierResponse.text, rateLabel: rateLabel, priceLabel: priceLabel})
      priceTierResponse.text = selectHtml
      return priceTierResponse
    })
  }

  updatePriceTiers(interpolatedResponse) {
    this.priceTierTargets.forEach(priceTier => {
      this.updateSelect2(priceTier, interpolatedResponse)
    })

    this.calculate()
  }

  clearPrices() {
    this.priceTierTargets.forEach(tariff => {
      $(tariff)
        .empty()
        .prop("disabled", true)
    })

    $(this.priceLabelTarget).empty()

    this.calculate()
  }

  clearPriceLabel() {
    $(this.priceLabelTarget).empty()
  }

  calculate() {
    // Subtotal
    let price = parseFloat($(this.priceTierTarget).filter(":visible").find(":selected").val() ||
                           $(this.priceTarget).filter(":visible").val())

    // No negative quantiaties so we only use 0 in the calculation
    let billedPerQuantity = parseFloat(this.billedPerQuantityTarget.value) < 0 ? 0 : parseFloat(this.billedPerQuantityTarget.value)
    let billedByQuantity = parseFloat(this.billedByQuantityTarget.value) ? parseFloat(this.billedByQuantityTarget.value) : 1
    let subtotal = parseFloat(price * billedPerQuantity * billedByQuantity)

    this.subtotalTarget.innerHTML  = isNaN(subtotal) ? 0 : subtotal.toFixed(2)

    // Tax
    // No negative tax rates so we use 0 in the calculation
    let taxRate = parseFloat($(this.taxRateTargets).filter(":visible").text() || $(this.manualTaxRateTarget).filter(":visible").val())
    taxRate = taxRate < 0 ? 0 : taxRate

    let tax = parseFloat(subtotal * (taxRate / 100))

    this.taxTarget.innerHTML = isNaN(tax) ? 0 : tax.toFixed(2)

    // Line Total
    let lineTotal = subtotal + tax
    this.lineTotalTarget.innerHTML = isNaN(lineTotal) ? 0 : lineTotal.toFixed(2)

    // Emit event for invoice controller to recalculate
    this.element.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
