import { select2Defaults } from '../utilities/select2_defaults'
import $ from 'jquery';

require('select2')

export const useSelect2 = controller => {
  Object.assign(controller, {
    setupSelect2() {
       return $(this.element).select2(select2Defaults(this.element))
        .on('select2:close', function () {
          $(this).data('select2').trigger('focus')
        })
    },

    updateSelect2(selectElement, dataArray) {
      // Store the currently selected (if there is one) option
      let selected = $(selectElement).find(":selected").filter((i, e) => e !== "")

      // Clear out store selected option (if there is one) so that when we append options
      // we don't include duplicates
      $(selectElement).empty()

      // When passing data array directly to select2, it doesn't add additional attributes to
      // option element as the ajax method does so we manually create options and append
      let options = dataArray.map(o => {

        const optionElement = new Option(o.text, o.id, false, false)
        // NOTE in the last part of the conditional, we want to only check value matching but not
        // type
        if (selected.length > 0 && selected[0].value !== "" && o.id == selected[0].value) {
          $(optionElement).attr("selected", "selected")
        }

        // Set all attr/props from response data onto options
        Object.entries(o).forEach(([key, value]) => {
          if (key === "id" || key === "text") {
            return
          }
          else if (key === "title") {
            $(optionElement).attr("title", o.title)
          } else {
            optionElement.dataset[key] = value
          }
        })

        return optionElement
      })

      const placeholderOption = new Option("", "", false, false)

      $(selectElement)
        .append(placeholderOption)
        .append(options)
        .on('select2:close', function () {
          $(selectElement).data('select2').trigger('focus')
        })
        .prop("disabled", options.length < 1)
    }
  });
};
