import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

import { useSelect2 } from "../mixins/use_select2";

// Initializes select2 fields
export default class extends Controller {
  connect() {
    useSelect2(this)

    // Select2 doesn't fire native events, so we fire them to be able to use the default
    // form actions for stimulus (i.e. on a select element data-action="controller#action" instead
    // of data-action="select2:select->controller#action"
    this.setupSelect2()
      .on('change.select2', () => {
        // Just to correct the inconsistent pattern
        this.element.dispatchEvent(new Event("select2:change", { bubble: true }))

        this.element.dispatchEvent(new Event("stimulus:change", { bubble: true }))
      }).on('select2:clear', () => {
        this.element.dispatchEvent(new Event("stimulus:clear", { bubble: true }))
      }).on('select2:select', () => {
        this.element.dispatchEvent(new Event("stimulus:select", { bubble: true }))
      }).on('select2:open', () => {
        // NOTE After a jquery update select2 focusing is broken check this issue for more details
        // and to see if we can remove this fix: https://github.com/select2/select2/issues/5993
        document.querySelector('.select2-container--open .select2-search__field').focus();
      })


    // Fire init for other controllers to know `data("select2")` is available and in general know
    // that select2 is ready
    $(this.element).trigger("select2:init")
  }
}
