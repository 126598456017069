import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source']

  copy(event) {
    event.preventDefault();

    const copyButton = event.currentTarget;
    const textToCopy = this.sourceTarget.textContent;

    navigator.clipboard.writeText(textToCopy).then(() => {
      // Store a reference to the original button's parent
      const parent = copyButton.parentNode;

      // Store the original button's content for re-insertion later
      const originalButton = copyButton.cloneNode(true);

      // Create the tick mark span
      const checkMark = document.createElement("span");
      checkMark.className = "glyphicon glyphicon-ok pull-right";
      checkMark.style.color = "green";
      checkMark.style.margin = "5px";

      // Replace the button with the tick mark
      $(copyButton).tooltip('destroy');
      parent.replaceChild(checkMark, copyButton);

      // Revert back to the original button after 2 seconds
      setTimeout(() => {
        parent.replaceChild(originalButton, checkMark);
        $(originalButton).tooltip();
      }, 2000);
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  }
}
