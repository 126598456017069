import { Controller } from "@hotwired/stimulus"

import { useShowFormErrors } from '../mixins/use_show_form_errors'

import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "addField",
    "fieldsContainer",
  ]

  connect() {
    useShowFormErrors(this)
    // this.handleRequiredFields()

    // Mutation observer to handle managing required fields as the form changes
    const observer = new MutationObserver(this.handleRequiredFields);
    observer.observe(this.element, {subtree: true, childList: true});
  }

  addField(e) {
    let time = new Date().getTime()
    let regexp = new RegExp($(this.addFieldTarget).data('id'), 'g')
    let field = $(this.addFieldTarget).data('fields').replace(regexp, time)
    $(this.fieldsContainerTarget).before(field)

    this.element.dispatchEvent(new Event("change", { bubble: true }))
  }

  removeImage(e) {
    const element = e.currentTarget

    $(element)
      .closest('.thumbnail')
      .remove()
  }

  removeFields(e) {
    const element = e.currentTarget

    this.handleRequiredFields()
    this.setDestroyParam(element)
    this.hideRow(element)

    this.element.dispatchEvent(new Event("change", { bubble: true }))
  }

  handleRequiredFields() {
    // Set required on visible fields
    $('input,textarea,select').filter('[data-field-required]').attr('required', true)
    $('input,textarea,select').filter('[data-field-required]').removeAttr('data-field-required')

    // Remove required but tag hidden fields to be reset if visible again
    $('input,textarea,select').filter('[required]:hidden').attr('data-field-required', true)
    $('input,textarea,select').filter('[required]:hidden').removeAttr('required')
  }

  setDestroyParam(element) {
    $(element)
      .prev('input[type=hidden][name*="_destroy"]')
      .val('1')
  }

  hideRow(element) {
    $(element)
      .closest('tbody')
      .hide()
  }
}
