import { Controller } from "@hotwired/stimulus"

import $ from 'jquery'

export default class extends Controller {
  static outlets = [ "filterable-item" ]

  static values = {
    id: Number,
    filters: Object,
    isFiltered: Boolean
  }

  static targets = [
    "modalButton",
    "filterButton",
    "searchField",
    "filterLabel",
    "clearButton"
  ]

  connect() {
    // Retrieve saved filter values from local storage
    const filterStr = localStorage.getItem("filterWorkQueue" + this.idValue)
    if (filterStr) {
      this.filtersValue = JSON.parse(filterStr) || {}
      this.filterItems()
    }

    // Update filter button UI based on saved filters
    this.filterButtonTargets.forEach(button => {
      const filterType = button.dataset.filterType
      const filters = this.filtersValue[filterType] || []
      const isSelected = filters.includes(button.textContent.trim())

      if (isSelected) {
        button.classList.add("_selected")

        // Add _selected class to corresponding modal button
        this.modalButtonTargets.forEach(modalButton => {
          if (modalButton.dataset.filterType === filterType) {
            modalButton.classList.add("_selected")
          }
        })
      } else {
        button.classList.remove("_selected")
      }
    })

    // Update search field UI based on saved search term
    const searchStr = localStorage.getItem("searchWorkQueue" + this.idValue)
    if (searchStr) {
      $(this.searchFieldTarget).val(searchStr)
      this.searchItems(new RegExp(searchStr, 'i'))
    }

    this.updateIsFilteredValue()
  }

  filterItems() {
    $(() => {
      this.filterableItemOutlets.forEach(item => {
        item.filter(this.filtersValue)
      })
    })
  }

  searchItems(searchTerm) {
    $(() => {
      this.filterableItemOutlets.forEach(item => {
        item.searchFilter(searchTerm)
      })
    })
  }

  select(e) {
    this.updateFilters(e)

    if (e.target.classList.contains("_selected")) {
      this.unfilter(e)
    } else {
      this.filter(e)
    }
  }

  updateFilters(e) {
    const filter = e.target.text
    const filterType = e.target.dataset.filterType
    let newObject = {...this.filtersValue}

    if (this.filtersValue[filterType] && this.filtersValue[filterType].includes(filter)) {
      newObject[filterType].splice(this.filtersValue[filterType].indexOf(filter), 1)
    } else {
      if (this.filtersValue[filterType]) {
        newObject[filterType] = [filter, ...newObject[filterType]]
      } else {
        newObject[filterType] = [filter]
      }
    }

    localStorage.setItem("filterWorkQueue" + this.idValue, JSON.stringify(newObject))
    this.filtersValue = newObject
  }

  filter(e) {
    const filterType = e.target.dataset.filterType

    e.target.classList.add("_selected")

    this.filterItems()

    this.toggleModalButtonSelected(filterType)
  }

  unfilter(e) {
    const filterType = e.target.dataset.filterType

    e.target.classList.remove("_selected")

    this.filterItems()

    this.toggleModalButtonSelected(filterType)
  }

  search(e) {
    const q = $(e.target).val()
    localStorage.setItem("searchWorkQueue" + this.idValue, q)
    this.searchItems(new RegExp(q, 'i'))

    this.updateIsFilteredValue()
  }

  updateIsFilteredValue() {
    // Check if there are no applied filters and the search term is empty
    const hasNoFilters = Object.values(this.filtersValue).every(filters => filters.length === 0)
    const isSearchEmpty = $(this.searchFieldTarget).val().trim().length === 0

    this.isFilteredValue = !(hasNoFilters && isSearchEmpty)
  }

  toggleModalButtonSelected(filterType) {
    const selectedFilters = this.filterButtonTargets.filter(button => {
      return (button.dataset.filterType === filterType && button.classList.contains("_selected"))
    })

    if (selectedFilters.length !== 0) {
      this.modalButtonTargets.forEach(button => {
        if (button.dataset.filterType === filterType) {
          button.classList.add("_selected")
        }
      })

    } else {
      this.modalButtonTargets.forEach(button => {
        if (button.dataset.filterType === filterType) {
          button.classList.remove("_selected")
        }
      })
    }
  }

  isFilteredValueChanged(value, _previousValue) {
    if (value) {
      this.clearButtonTarget.classList.add("btn-warning")
      this.element.classList.remove("panel-default")
      this.element.classList.add("panel-warning")
      $(this.filterLabelTarget).show()
    } else {
      this.clearButtonTarget.classList.remove("btn-warning")
      this.element.classList.remove("panel-warning")
      this.element.classList.add("panel-default")
      $(this.filterLabelTarget).hide()
    }
  }

  filtersValueChanged(_value, _previousValue) { this.updateIsFilteredValue() }

  clearSearchAndFilter(e) {
    e.preventDefault()

    localStorage.removeItem("filterWorkQueue" + this.idValue)
    this.filtersValue = {}
    this.filterItems()

    localStorage.removeItem("searchWorkQueue" + this.idValue)
    $(this.searchFieldTarget).val(null)
    this.searchItems("")

    this.isFilteredValue = false

    this.modalButtonTargets.forEach(button => {
        button.classList.remove("_selected")
    })
    this.filterButtonTargets.forEach(button => {
        button.classList.remove("_selected")
    })
  }
}
