import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customerType", "customerSelect"];

  updateCustomerSelectUrl() {
    const customerType = this.customerTypeTarget.value;
    const customerSelect = this.customerSelectTarget;

    const newUrl = `/autocomplete/customers?customer_type=${encodeURIComponent(customerType)}`;
    customerSelect.dataset.select2AjaxUrl = newUrl;
  }
}
