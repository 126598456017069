export default {
  convert({ unit: fromUnit, value: fromValue }, { unit: toUnit }){
    if(fromUnit !== 'kg') throw('from unit is not supported')
    if(toUnit !== 'mt')   throw('to unit is not supported')
    return { unit: toUnit, value: fromValue / 1000 }
  },

  format({ unit, value }, { round = 2 } = {}){
    const roundedNumber = Math.round(value * Math.pow(10, round)) / Math.pow(10, round)
    return `${roundedNumber} ${unit}`
  }
}
