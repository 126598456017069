import { Controller } from "@hotwired/stimulus"

import $ from "jquery"

export default class extends Controller {
  static targets = [ "proforma", "blankProforma", "paymentTotal", "proformasCount" ]
  static values = { blankProformasVisible: { type: Boolean, default: true }}

  // This functionality isn't needed yet for R1, coming soon when we want to select different
  // proformas
  connect() {
    // this.updatePaymentInfo()
  }

  toggleProformas() {
    if (this.blankProformasVisibleValue) {
      this.hideBlankProformas()
      this.blankProformasVisibleValue = false
    } else {
      this.showBlankProformas()
      this.blankProformasVisibleValue = true
    }
  }

  showBlankProformas(e) {
    $(this.blankProformaTargets).removeClass("hidden")
  }

  hideBlankProformas(e) {
    $(this.blankProformaTargets).addClass("hidden")
  }

  // TODO use stimulus js class api
  toggleInvoice(e) {
    // e.currentTarget.classList.toggle("_focused")
    // this.updatePaymentInfo()
  }

  // TODO it would still nice to have a currency formatter like rails
  // TODO are we concerned about mixing currencies here?
  updatePaymentInfo() {
    // const selectedProformas = this.proformaTargets.
    //   filter(el => el.classList.contains("_focused") )
    //
    // const selectedProformasTotal = selectedProformas.map(el => el.dataset.proformaTotal).
    //   reduce((output, proformaTotal) => {
    //   return output += (parseFloat(proformaTotal) || 0)
    //   }, 0)
    //
    // this.proformasCountTarget.innerHTML = selectedProformas.length
    // this.paymentTotalTarget.innerHTML = parseFloat(selectedProformasTotal).toFixed(2)
  }
}
