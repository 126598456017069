import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["locationInput"]

  update(event) {
    const url = event.params.url
    const containerId = event.params.containerId
    const voyageId = event.params.voyageId
    const containerSelector = `input[data-container-vessel-location-container-id-param=${containerId}]`
    const stowageLocation = this.locationInputTarget.value
    let data = {"container_vessel_location": {}}
    data["container_vessel_location"]["stowage_cell"] = stowageLocation
    data["container_vessel_location"]["voyage_id"] = voyageId

    if ([6, 7].includes(stowageLocation.length)) {
      $.ajax(url, {
        data: data,
        method: "POST",
        dataType: "json",
        success: function() {
          $(containerSelector).highlight()
        },
        error: function (request) {
          $(containerSelector).highlight("red")
          $(containerSelector).val("")
          alert(`We could not update the position. ${request.responseText}`)
        }
      })
    } else {
      $(containerSelector).highlight("red")
      $(containerSelector).val("")
    }
  }
}
