import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'output'
  ]

  // This allows users to preview the equipment
  // damage images.
  showPreview() {
    var input = this.inputTarget
    var output = this.outputTarget
    var lastRow = 1

    if (input.files && input.files[0]) {
      if (input.files.length > 6) {
        alert('Please select less than 6 files for upload.')
        this.inputTarget.value = null;
        output.innerHTML = '';
      } else if (input.files.length < 7 && input.files.length > 0) {
        // Clear out the old images
        output.innerHTML = '';

        for (var i = 0; i < input.files.length; i++) {
          let file = input.files[i]
          let reader = new FileReader();
          let img = document.createElement('img');
          img.setAttribute('class', 'img-responsive img-rounded');

          reader.onload = function () {
            img.setAttribute('src', reader.result);
          }
          reader.readAsDataURL(file);

          if (i % 3 == 0) {
            // Start a new row
            var row = document.createElement('div')
            row.setAttribute('class', 'row')
            row.setAttribute('style', 'margin-top: 1em;')
            row.setAttribute('id', 'img-preview-row-' + lastRow)
          } else if (i % 3 == 2) {
            // Set the row element and increment the lastRow counter
            // since this is the final image in the row.
            var row = document.getElementById('img-preview-row-' + lastRow)
            lastRow += 1
          } else {
            // Set the row element
            var row = document.getElementById('img-preview-row-' + lastRow)
          }
          var col = document.createElement('div')
          col.setAttribute('class', 'col-xs-4')
          col.appendChild(img)
          row.appendChild(col)
          output.appendChild(row);
        }
      }
    }
  }
}
