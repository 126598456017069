import $ from 'jquery';

export const useShowFormErrors = (controller) => {
  (() => {
    // Add error class on invalid
    $(controller.element).find('input, select, textarea').on('invalid', function () {
      if ($(this).hasClass('select2-hidden-accessible')) {
        $(this)
          .next()
          .find('.select2-selection')
          .addClass('has-error')
      } else {
        $(this).addClass('has-error')
      }
    })

    // Remove error class on valid
    $(controller.element).find('input, textarea').on('input', function () {
      if (this.validity.valid) {
        if ($(this).hasClass('select2-hidden-accessible')) {
          $(this)
            .next()
            .find('.select2-selection')
            .removeClass('has-error')
        } else {
          $(this).removeClass('has-error')
        }
      }
    })

    $(controller.element).find('select').on('change select2:change', function () {
      if (this.validity.valid) {
        if ($(this).hasClass('select2-hidden-accessible')) {
          $(this)
            .next()
            .find('.select2-selection')
            .removeClass('has-error')
        } else {
          $(this).removeClass('has-error')
        }
      }
    })
  })()
};
