import { Controller } from "@hotwired/stimulus"

import "../../assets/javascripts/batch_actions"
import "../../assets/javascripts/batch_actions_modal"
import "../../assets/javascripts/containers_load_list_modal"

export default class extends Controller {
  static targets = [
    "modalButton"
  ]

  static values = {
    mode: String
  }

  connect() {
    this.Octopi = window.Octopi || {}

    window.addEventListener("load", () => {
      var checkboxesSelector = '.octo-cell__default > input[type="checkbox"]'
      var batchActions = this.Octopi.BatchActions
      var loadListModal = this.Octopi.Containers.LoadListModal
      var extractSelectedCargoState = this.extractSelectedCargoState
      var itemToInputAttrs = this.itemToInputAttrs

      batchActions.init(checkboxesSelector, extractSelectedCargoState)
      loadListModal.init(batchActions.getState(), itemToInputAttrs)

      this.initializeModal('#apply-holds-modal', 'form#bulk-apply-holds-containers-form', '#apply-holds-modal-submit')
      this.initializeModal('#lift-holds-modal', 'form#bulk-lift-holds-containers-form', '#lift-holds-modal-submit')
      this.initializeModal('#stage-for-train-visit-modal', 'form#stage-for-train-visit-form', '#stage-for-train-visit-modal-submit')
      this.initializeModal('#add-service-modal', 'form#bulk-update-services-form', '#add-service-modal-submit')
      this.initializeTagsModalFor('add')
      this.initializeTagsModalFor('remove')
      this.initializeTransferRequestModal()
    })
  }

  // This method collects data from yard area show page (grid)  and returns an object.
  // Changes to the attribute names needs to be propagated to `container.js`.
  extractSelectedCargoState(selectedItems) {
    return selectedItems.map(function() {
      var containerId = $(this).val()

      return ({
        containerId: containerId,
        // cargo is the container number on yard area grid view
        cargo: this.dataset.containerNumber,
        bol: '',
        booking: '',
        status: this.dataset.containerStatus
      })
    })
  }

  // Changes here needs to be replicated on container.js
  itemToInputAttrs(item) {
    return ({
      type: 'hidden',
      id: 'bulk-update-containers-' + item.containerId,
      name: 'bulk_update[containers][' + item.containerId + ']',
      value: item.status
    });
  }

  initializeTagsModalFor(addOrRemove) {
    var submitSelector = '#'.concat(addOrRemove, '-tags-modal-submit');
    var tagsSelectSelector = '#'.concat(addOrRemove, '-tags-input');
    var modalSelector = '#'.concat(addOrRemove, '-tags-modal');
    var formSelector = 'form#bulk-update-'.concat(addOrRemove, '-tags-form');

    this.initializeModal(modalSelector, formSelector, submitSelector);
    this.toggleSubmitButton(tagsSelectSelector, submitSelector);

    $(tagsSelectSelector).on('select2:change', this.toggleSubmitButton.bind(null, tagsSelectSelector, submitSelector));
  }

  toggleSubmitButton(valueSelector, submitSelector) {
    if($(valueSelector).val() && $(valueSelector).val().length) {
      $(submitSelector).prop('disabled', false);
    } else {
      $(submitSelector).prop('disabled', true);
    }
  }

  initializeModal(modalSelector, formSelector, submitSelector) {
    $(modalSelector).on('show.bs.modal', this.Octopi.BatchActionsModal.initializeModal.bind(
      null, modalSelector, formSelector, this.Octopi.BatchActions.getState(), this.itemToInputAttrs
    ));

    $(submitSelector).on('click', this.Octopi.BatchActionsModal.submitForm.bind(null, submitSelector, formSelector));
  }

  initializeTransferRequestModal() {
    var submitSelector = '#transfer-request-modal-submit'

    this.initializeModal('#transfer-request-modal', 'form#bulk-transfer-requests-form', submitSelector)
    this.toggleTransferRequestSubmitButton(submitSelector)

    $('#transfer_request_terminal_id').on('select2:change', this.toggleTransferRequestSubmitButton.bind(null, submitSelector))
    $('#destination_terminal').on('select2:change', this.toggleTransferRequestSubmitButton.bind(null, submitSelector))

    $('#transfer_request_other_id').on('select2:change', this.toggleTransferRequestSubmitButton.bind(null, submitSelector))
    $('#destination_other').on('select2:change', this.toggleTransferRequestSubmitButton.bind(null, submitSelector))
  }

  toggleTransferRequestSubmitButton(submitSelector) {
    if ($('#destination_terminal').prop('checked')) {
      $('#transfer_request_other_id').val('')
    }

    if ($('#destination_other').prop('checked')) {
      $('#transfer_request_terminal_id').val('')
    }

    var canSubmit = $('#transfer_request_terminal_id').val() || $('#transfer_request_other_id').val()
    $(submitSelector).prop('disabled', !canSubmit)
  }
}
