import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = ['servicesDropdown'];

  /////////////////
  //// Actions ////
  /////////////////

  refreshServiceOptions(event) {
    const cargoType = event.currentTarget.value;
    const isContainer = cargoType === 'container';

    $(this.servicesDropdownTarget)
      .find('option')
      .each(function(_, option) {
        const $option = $(option);
        const isDefaultOption = isNaN(Number(option.value));

        $option.prop('disabled', !isContainer && isDefaultOption);

        if (isDefaultOption && $option.is(':selected')) {
          $option.prop('selected', false);
        }
      });

    // Trigger the 'change' event to reload options
    $(this.servicesDropdownTarget).trigger('change');
  }
}
